import React, { useEffect } from "react";
import Admintable from "./Admintable";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import {
  initialCommonparems,
  LoadDefaultstates,
  ValidateUser_Permission,
} from "./features/Common";

function ErrorLogs() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);

  useEffect(() => {
    Load_Errorlog();
  }, []);

  const Load_Errorlog = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_Errorlog").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 10,
              Data: res.data.EntLogs,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(10),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Truncate_Errorlog = async () => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp
        .post("ClientMaster/Truncate_Errorlog")
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            await Load_Errorlog();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      <Admintable IsBackground={false} />
      <div className="btn-section">
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            dispatch(
              Show_MsgBox({
                Button: Truncate_Errorlog,
                Question: "Do You Want to Delete All Error Log?",
                isdialog: true,
              })
            );
          }}
          disabled={!_common.permission.IsDelete}
        >
          Delete Error Log&nbsp;<i className="bx bx-trash-alt"></i>
        </button>
      </div>
    </>
  );
}

export default ErrorLogs;

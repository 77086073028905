import React, { useEffect, useState } from "react";
import Logo from "./Image/JustBill.png";
import $ from "jquery";
import adminApp, { EncryptData } from "./Utility";
import { useNavigate } from "react-router-dom";
function AdminLogin() {
  const navigate = useNavigate();
  const [strErrMsg, setstrErrMsg] = useState("");
  const [strErrName, setstrErrName] = useState("");
  const [strErrPass, setstrErrPass] = useState("");
  const [strClassName, setstrClassName] = useState("fa-solid fa-eye-slash");
  const [strUserName, setstrUserName] = useState("");
  const [strPassword, setstrPassword] = useState("");

  useEffect(() => {
    if (localStorage.jbadmparetkau) navigate("/admin/Client-Info");
  }, []);
  const PassVisible = () => {
    var x = document.getElementById("password-field");
    if (x.type === "password") {
      x.type = "text";
      setstrClassName("fa-solid fa-eye");
    } else {
      setstrClassName("fa-solid fa-eye-slash");
      x.type = "password";
    }
  };
  const SignIn = async () => {
    try {
      setstrErrMsg("");
      if (strUserName === "") {
        setstrErrMsg("");
        setstrErrName("Invalid User Name");
      } else if (strPassword === "") {
        setstrErrMsg("");
        setstrErrName("");
        setstrErrPass("Invalid Password");
      } else {
        setstrErrMsg("");
        setstrErrName("");
        setstrErrPass("");
        var isLoading = false;
        var interval = setInterval(function () {
          isLoading = !isLoading;
          Loginloading(isLoading);
        }, 800);
        const User = {
          strUserName: strUserName,
          strPassword: strPassword,
        };
        await adminApp
          .post(`UserMaster/Authorize_Admin/`, User)
          .then(async (Res) => {
            if (Res.data.strStatus === "success") {
              // Store the token in localStorage
              localStorage.setItem("jbadmparetkau", Res.data.User.token);

              // Encrypt and store permission data in localStorage
              const encryptedData = await EncryptData({
                mnper: JSON.stringify(Res.data.Permission),
                isLogout: Res.data.User.iLogOut,
                User: Res.data.User.iUserId,
              });
              localStorage.setItem("jbctlparm", encryptedData);
              navigate("/admin/Client-Info");
            } else setstrErrMsg(Res.data.strMessage);
          });
      }
    } catch (err) {
      clearInterval(interval);
      setstrErrMsg(err.message);
    } finally {
      clearInterval(interval);
    }
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowDown") {
      if (ActiveIndex > 1 && ActiveIndex <= 7)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 6 + "']").focus();
    }
    if (e.key === "ArrowUp") {
      if (ActiveIndex >= 1 && ActiveIndex < 7)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 7) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else if (ActiveIndex === 3) SignIn();
      else $("[tabindex='" + 1 + "']").focus();
    }
  };
  const Loginloading = (isLoading) => {
    var element = document.getElementById("Loginloading");
    if (isLoading) {
      element.classList.add("login_progress");
    } else {
      element.classList.remove("login_progress");
    }
  };
  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-Img">
          <img src={Logo} alt="JustBill"></img>
        </div>
        <div className="login-input">
          <div className="login-header">
            <h6>Let's get Started</h6>
            <label>Sign In To Continue</label>
          </div>
          <p className="err-msg">{strErrMsg}</p>
          <div className="mt-3">
            <label>User Name</label>
            <div className="login-usepass">
              <i className="fa-solid fa-user"></i>
              <input
                spellCheck={false}
                value={strUserName}
                onChange={(e) => setstrUserName(e.target.value)}
                type="text"
                placeholder="Username"
                autoFocus
                tabIndex={1}
                onKeyDown={(e) => TabIndex(e)}
              />
            </div>
            <p className="err-msg">{strErrName}</p>
          </div>
          <div className="mt-3">
            <label>Password</label>
            <div className="login-usepass">
              <i className="fa-solid fa-lock"></i>
              <input
                spellCheck={false}
                value={strPassword}
                onChange={(e) => setstrPassword(e.target.value)}
                id="password-field"
                type="password"
                tabIndex={2}
                onKeyDown={(e) => TabIndex(e)}
                placeholder="Password"
              />
              <span>
                <i className={strClassName} onClick={PassVisible}></i>
              </span>
            </div>
            <p className="err-msg">{strErrPass}</p>
          </div>
        </div>
        <div className="login-btn">
          <button onKeyDown={(e) => TabIndex(e)} tabIndex={3} onClick={SignIn}>
            Sign In
          </button>
        </div>
        <div id="Loginloading"></div>
      </div>
    </div>
  );
}
export default AdminLogin;

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import $ from "jquery";
import { useEffect } from "react";
import { Hide_AlertMsgBox, Hide_DialogMsgBox } from "./features/Message";
function Messagedialogbox() {
  const dispatch = useDispatch();
  const _Msgparem = useSelector((state) => state.Message.Msg_Parem);
  const [strIcon, setstrIcon] = useState("");

  useEffect(() => {
    if (_Msgparem.isMsg) {
      Show_Message();
    }
  }, [_Msgparem.isMsg]);
  useEffect(() => {
    if (_Msgparem.isdialog) {
      Show_Dialog();
    }
  }, [_Msgparem.isdialog]);

  const Show_Message = async () => {
    switch (_Msgparem.Type) {
      case "error":
        setstrIcon("fa-solid fa-triangle-exclamation");
        break;
      case "success":
        setstrIcon("fa-solid fa-circle-check");
        break;
      case "info":
        setstrIcon("fa-solid fa-circle-exclamation");
        break;
      default:
        break;
    }
    $(".alertMsg").removeClass("hide");
    $(".alertMsg").addClass("Show");
    $("html, body").animate({
      scrollTop: $(".alertMsg").offset().top - 100,
    });
    $(".close-btn").on("click", async function () {
      $(".alertMsg").addClass("hide");
      $(".alertMsg").removeClass("Show");
      await dispatch(Hide_AlertMsgBox());
    });
    setTimeout(async function () {
      if ($(".alertMsg").hasClass("Show")) {
        $(".alertMsg").addClass("hide");
        $(".alertMsg").removeClass("Show");
        await dispatch(Hide_AlertMsgBox());
      }
    }, 10000);
  };
  const Show_Dialog = () => {
    $("#Msgtp").on("mouseover", function () {
      $(this).toggleClass("mouseover");
      $(".MsgTooltip").toggleClass("active");
    });
    $("#Msgtp").on("mouseout", function () {
      $(".MsgTooltip").removeClass("active");
    });
    $("#CloseMsg").on("mouseover", function () {
      $(this).toggleClass("mouseover");
      $(".MsgClose").toggleClass("Close");
    });
    $("#CloseMsg").on("mouseout", function () {
      $(".MsgClose").removeClass("Close");
    });
    $("#CloseMsg").on("click", function () {
      $(".Msg-Popup").addClass("PopupClose");
    });
  };
  const On_KePress = async (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowDown") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 3 + "']").focus();
    } else if (e.key === "ArrowUp") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "ArrowLeft") {
      if (ActiveIndex > 1 && ActiveIndex <= 3)
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
      else $("[tabindex='" + 3 + "']").focus();
    } else if (e.key === "ArrowRight") {
      if (ActiveIndex >= 1 && ActiveIndex < 3)
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
      else $("[tabindex='" + 1 + "']").focus();
    } else if (e.key === "Enter") {
      if (ActiveIndex === 1) {
        await dispatch(Hide_DialogMsgBox());
        await _Msgparem.Button(
          _Msgparem.parem1,
          _Msgparem.parem2,
          _Msgparem.parem3,
          _Msgparem.parem4
        );
      } else await dispatch(Hide_DialogMsgBox());
    }
  };
  return (
    <>
      {/* Alert Message */}
      {_Msgparem.isMsg && (
        <div className="alertMsg hide">
          <i className={strIcon}></i>
          <span className="msg">{_Msgparem.Message}</span>
          <i
            className="fa fa-times close-btn"
            onClick={async (e) => await dispatch(Hide_AlertMsgBox())}
          ></i>
        </div>
      )}
      {/* Dialog Box */}
      {_Msgparem.isdialog && (
        <div className="Msg-Popup">
          <div className="Msg-Container">
            <div className="Msg-Header">
              <i id="Msgtp" className="fa-solid fa-circle-exclamation"></i>
              <i
                id="CloseMsg"
                className="fa-regular fa-circle-xmark"
                onClick={async (e) => await dispatch(Hide_DialogMsgBox())}
                tabIndex="3"
                onKeyDown={(e) => On_KePress(e)}
              ></i>
            </div>
            <div className="Msg-Body">
              <p>{_Msgparem.Question}</p>
            </div>
            <div className="Msg-Footer">
              <button
                onClick={async (e) => {
                  await dispatch(Hide_DialogMsgBox());
                  await _Msgparem.Button(
                    _Msgparem.parem1,
                    _Msgparem.parem2,
                    _Msgparem.parem3,
                    _Msgparem.parem4
                  );
                }}
                className="px-3"
                tabIndex="1"
                onKeyDown={(e) => On_KePress(e)}
                autoFocus
              >
                <i className="fa-solid fa-thumbs-up"></i>
                Yes
              </button>
              <button
                onClick={async (e) => await dispatch(Hide_DialogMsgBox())}
                className="px-3"
                tabIndex="2"
                onKeyDown={(e) => On_KePress(e)}
              >
                <i className="fa-solid fa-xmark"></i>
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Loading Screen */}
      {_Msgparem.isloading && (
        <div className="Loading-Popup">
          <div className="Loading-Design">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default Messagedialogbox;

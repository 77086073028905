import React, { useEffect } from "react";
import Admintable from "./Admintable";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import CustomizedSelectoption from "./CustomizedSelectoption";
import {
  clearDefaultstates,
  clearEnquiry,
  enquiryInputOnchange,
  initialCommonparems,
  LoadDefaultstates,
  shownewenquiry,
  ValidateUser_Permission,
} from "./features/Common";
import moment from "moment";
import SendOTP from "./generateOTP";
import OTPValidation from "./OTPValidation";

function EnquiryMaster() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const _enquiry = useSelector((store) => store.Common.enquiry);
  const _select = useSelector((state) => state.SelectOption.parems);
  useEffect(() => {
    Load_Enquiry();
  }, []);
  const Load_Enquiry = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_Enquirys").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 4,
              Data: res.data.Enquiry,
              Columns: res.data.Columns,
              Status: res.data.Status,
              permission: await ValidateUser_Permission(4),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const InsUpd_ClientEnquiry = async () => {
    try {
      await dispatch(setload_Screen(true));
      const Enquiry = {
        EnquiryId: _enquiry.iEnquiryId,
        CompanyName: _enquiry.strClientName,
        Add1: _enquiry.strAdd1,
        Add2: _enquiry.strAdd2,
        Add3: _enquiry.strAdd3,
        Add4: _enquiry.strAdd4,
        Pincode: _enquiry.strPinCode,
        Mobile: _enquiry.strMobile,
        GST: _enquiry.strGST,
        Email: _enquiry.strEmail,
        Referral: _enquiry.strRefferel,
        EnquiryDate: _enquiry.EnquiryDate,
        Status: _enquiry.iStatus,
        ActiveDate: _enquiry.ActiveDate,
      };
      await adminApp
        .post("ClientMaster/InsUpd_ClientEnquiry", Enquiry)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await Close_Enquiry();
            await AlertMessage(res.data.strMessage, "success");
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_ClientEnquiry = async () => {
    try {
      if (Number(_enquiry.iEnquiryId) === 0) {
        AlertMessage("Please Select Enquiry", "info");
      } else {
        await adminApp
          .post(
            `ClientMaster/Delete_ClientEnquiry/${Number(_enquiry.iEnquiryId)}`
          )
          .then(async (res) => {
            if (res.data.strStatus === "Success") {
              await Close_Enquiry();
              await AlertMessage(res.data.strMessage, "success");
            } else {
              AlertMessage(res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const Create_Client = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        strUserName: _enquiry.strUserName,
      };
      await adminApp
        .post(`UserMaster/CheckUserName`, parems)
        .then(async (result) => {
          if (result.data.strStatus === "Success") {
            let ActivationDate = moment();
            let ExpiredDate = moment().add(7, "days");
            ActivationDate = ActivationDate.format("YYYY-MM-DD");
            ExpiredDate = ExpiredDate.format("YYYY-MM-DD");
            const parems = {
              OTP: {
                iUserId: 0,
                Otp: _common.OTP,
                strUserName: _enquiry.strClientName,
                strotptype: _common.OtpType,
              },
              client: {
                ClientId: 0,
                ClientName: _enquiry.strClientName,
                Add1: _enquiry.strAdd1,
                Add2: _enquiry.strAdd2,
                Add3: _enquiry.strAdd3,
                Add4: _enquiry.strAdd4,
                PinCode: _enquiry.strPinCode,
                Mobile: _enquiry.strMobile,
                Email: _enquiry.strEmail,
                GST: _enquiry.strGST,
                ActivationDate: ActivationDate,
                ExpiryDate: ExpiredDate,
                Subscription: "7 Days",
                MasterDB: 0,
                strMasterDB: "",
                strUserName: _enquiry.strUserName,
                strPassword: _enquiry.strPassword,
                iStatus: 1,
                iUserId: 1,
                iRoleId: 1,
              },
            };
            await adminApp
              .post("ClientMaster/InsUpd_ClientMaster", parems)
              .then(async (res) => {
                if (res.data.strStatus === "Success") {
                  await InsUpd_ClientEnquiry();
                } else {
                  AlertMessage(res.data.strMessage, "error");
                }
              });
          } else {
            AlertMessage(result.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const ClearClick = async () => {
    await dispatch(clearEnquiry());
    await Load_Enquiry();
  };
  const Close_Enquiry = async () => {
    await dispatch(clearDefaultstates());
    await Load_Enquiry();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      {!_common.IsUpdate && (
        <>
          <Admintable IsBackground={true} />
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                dispatch(shownewenquiry());
              }}
            >
              New Enquiry&nbsp;<i className="bx bx-buildings"></i>
            </button>
          </div>
        </>
      )}
      {_common.IsUpdate && (
        <>
          <div className="card-wrapper">
            <div className="row p-2">
              <div className="col-sm-12 col-md-6">
                <label>Company Name</label>
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  tabIndex={1}
                  className="form-control"
                  name="strClientName"
                  value={_enquiry.strClientName}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Status</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_select.isoptionShow}
                  placeholder="Select State"
                  select_value={(val) =>
                    dispatch(
                      enquiryInputOnchange({
                        name: "iStatus",
                        value: val,
                      })
                    )
                  }
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={_enquiry.iStatus}
                  displayName="status"
                  disvalue="statusId"
                  arrydata={_common.Status}
                  EmptVal="State"
                  disabled={!_enquiry.IsNewClient}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="ActiveDate"
                  value={_enquiry.ActiveDate}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Door/Address No</label>
                <input
                  type="text"
                  placeholder="Enter Door/Address No"
                  tabIndex={1}
                  className="form-control"
                  name="strAdd1"
                  value={_enquiry.strAdd1}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Street/Area</label>
                <input
                  type="text"
                  placeholder="Enter Street/Area"
                  tabIndex={1}
                  className="form-control"
                  name="strAdd2"
                  value={_enquiry.strAdd2}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Village/Town</label>
                <input
                  type="text"
                  placeholder="Enter Village/Town"
                  tabIndex={1}
                  className="form-control"
                  name="strAdd3"
                  value={_enquiry.strAdd3}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>City</label>
                <input
                  type="text"
                  placeholder="Enter City"
                  tabIndex={1}
                  className="form-control"
                  name="strAdd4"
                  value={_enquiry.strAdd4}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Pincode</label>
                <input
                  type="number"
                  placeholder="Pincode"
                  tabIndex={1}
                  className="form-control"
                  name="strPinCode"
                  value={_enquiry.strPinCode}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Mobile</label>
                <input
                  type="number"
                  placeholder="Mobile Number"
                  tabIndex={1}
                  className="form-control"
                  name="strMobile"
                  value={_enquiry.strMobile}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  tabIndex={1}
                  className="form-control"
                  name="strEmail"
                  value={_enquiry.strEmail}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>GST</label>
                <input
                  type="text"
                  placeholder="Enter GST No"
                  tabIndex={1}
                  className="form-control"
                  name="strGST"
                  value={_enquiry.strGST}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                  maxLength={15}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>How to You Know About JustBill</label>
                <input
                  type="text"
                  placeholder="Enter Refferel"
                  tabIndex={1}
                  className="form-control"
                  name="strRefferel"
                  value={_enquiry.strRefferel}
                  onChange={(e) => dispatch(enquiryInputOnchange(e))}
                  autoComplete="off"
                  spellCheck={false}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <label>Enquiry Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="EnquiryDate"
                  defaultValue={_enquiry.EnquiryDate}
                  disabled
                />
              </div>
              {_enquiry.IsNewClient && (
                <div className="col-sm-12 col-md-3">
                  <label>User Name</label>
                  <input
                    type="text"
                    placeholder="Enter User Name"
                    tabIndex={6}
                    className="form-control"
                    name="strUserName"
                    value={_enquiry.strUserName}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => dispatch(enquiryInputOnchange(e))}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
              )}
              {_enquiry.IsNewClient && (
                <div className="col-sm-12 col-md-3">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    tabIndex={7}
                    className="form-control"
                    name="strPassword"
                    value={_enquiry.strPassword}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => dispatch(enquiryInputOnchange(e))}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
              )}
              {_enquiry.IsNewClient && (
                <div className="col-sm-12 col-md-3">
                  <label>Confirm Password</label>
                  <input
                    type="text"
                    placeholder="Enter Password"
                    tabIndex={8}
                    className="form-control"
                    name="strConfPassword"
                    value={_enquiry.strConfPassword}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => dispatch(enquiryInputOnchange(e))}
                    onBlur={(e) => {
                      if (e.target.value !== _enquiry.strPassword) {
                        AlertMessage("Password Doesn't Match", "info");
                      }
                    }}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
              )}
            </div>
            {_common.IsOTP && <OTPValidation Verifyclick={Create_Client} />}
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: InsUpd_ClientEnquiry,
                    Question: "Do You Want to Save Enquiry?",
                    isdialog: true,
                  })
                );
              }}
              disabled={!_common.permission.IsCreate}
            >
              Save&nbsp;<i className="fas fa-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              disabled={
                !_common.permission.IsDelete ? !_enquiry.IsNewClient : true
              }
              onClick={(e) => {
                dispatch(
                  Show_MsgBox({
                    Button: Delete_ClientEnquiry,
                    Question: "Do You Want to Delete Enquiry?",
                    isdialog: true,
                  })
                );
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            {_enquiry.IsNewClient && (
              <button
                disabled={!_common.permission.IsCreate}
                className="btn-fabgreen"
                onClick={(e) => {
                  if (_enquiry.strClientName.length === 0)
                    AlertMessage("Please Enter Company Name", "info");
                  else if (_enquiry.strUserName.length === 0)
                    AlertMessage("Please Enter UserName", "info");
                  else if (_enquiry.strPassword.length === 0)
                    AlertMessage("Please Enter Password", "info");
                  else if (_enquiry.strConfPassword === "")
                    AlertMessage("Please Enter Password", "info");
                  else if (_enquiry.strConfPassword !== _enquiry.strPassword)
                    AlertMessage("Password Doesn't Match", "info");
                  else {
                    dispatch(
                      Show_MsgBox({
                        Button: SendOTP,
                        Question: "Do You Want to Add New Client User?",
                        isdialog: true,
                        parem1: 1,
                        parem2: "New",
                        parem3: _enquiry.strClientName,
                        parem4: dispatch,
                      })
                    );
                  }
                }}
              >
                <i className="bx bx-buildings"></i>&nbsp;Create Organization
              </button>
            )}
            <button className="btn-fabgreen ml-2" onClick={ClearClick}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
            <button className="btn-fabgreen" onClick={(e) => Close_Enquiry()}>
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default EnquiryMaster;

import React, { useEffect } from "react";
import Admintable from "./Admintable";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import {
  initialCommonparems,
  LoadDefaultstates,
  ValidateUser_Permission,
} from "./features/Common";

function UserDatabase() {
  const dispatch = useDispatch();

  useEffect(() => {
    Load_Userdblist();
  }, []);

  const Load_Userdblist = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("DBMaster/Load_Userdblist").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 6,
              Data: res.data.Database,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(6),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return <Admintable IsBackground={false} />;
}

export default UserDatabase;

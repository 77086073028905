import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp, { calculate_keyexpiry } from "./Utility";
import Admintable from "./Admintable";
import CustomizedSelectoption from "./CustomizedSelectoption";
import ReactTable from "./ReactTable";
import {
  clearclientuserinput,
  clientuserInputOnchange,
  initialCommonparems,
  LoadDefaultstates,
  loaduserMaster,
  permissionallOnchange,
  showcolumn,
  showhideuserrole,
  updateUserPerm,
  updateUserState,
  ValidateUser_Permission,
  viewUser,
} from "./features/Common";
import UserRole from "./UserRole";
function Usermaster() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const _user = useSelector((state) => state.Common.clientuser);
  const _select = useSelector((state) => state.SelectOption.parems);

  useEffect(() => {
    Load_Clients();
  }, []);

  const Load_Clients = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_Clients").then(async (res) => {
        if (res.data.strStatus === "Success") {
          res.data.Clients.map(async (dt) => {
            dt.subscription = await calculate_keyexpiry(dt.expiryDate);
            return dt;
          });
          await dispatch(
            initialCommonparems({
              iScrId: 5,
              Data: res.data.Clients,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(5),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };

  useEffect(() => {
    const Load_UserMaster = async () => {
      try {
        if (_user.iClientId > 0) {
          await dispatch(setload_Screen(true));
          await adminApp
            .get(`UserMaster/Load_UserMaster/${_user.strMasterDB}`)
            .then((res) => {
              if (res.data.strStatus === "Success") {
                dispatch(loaduserMaster(res.data));
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        }
      } catch (err) {
        AlertMessage(err.message, "error");
      } finally {
        await dispatch(setload_Screen(false));
      }
    };
    Load_UserMaster();
  }, [_user.iClientId]);

  useEffect(() => {
    const View_User = async () => {
      try {
        if (_user.iUserId > 0) {
          await dispatch(setload_Screen(true));
          await adminApp
            .get(`UserMaster/View_User/${_user.iUserId}/${_user.strMasterDB}`)
            .then(async (res) => {
              if (res.data.strStatus === "Success") {
                await dispatch(viewUser(res.data));
                await View_PermColumn(Number(1));
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        }
      } catch (err) {
        AlertMessage(err.message, "error");
      } finally {
        dispatch(setload_Screen(false));
      }
    };
    View_User();
  }, [_user.iUserId]);

  const Load_UserPermssion = async (CatId, UId) => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp
        .get(
          `UserMaster/Load_UserPermission/${UId}/${CatId}/${_user.strMasterDB}`
        )
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await View_PermColumn(Number(CatId));
            await dispatch(
              updateUserPerm({
                Permission: res.data.Permission,
                iScrCatId: Number(CatId),
              })
            );
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Save_User = async () => {
    try {
      if (_user.strUserName === "") {
        AlertMessage("Pleaser Enter Valid User Name", "info");
      } else if (_user.strPassword === "") {
        AlertMessage("Pleaser Enter Valid Password", "info");
      } else if (_user.strPassword === !_user.strConfirmPassword) {
        AlertMessage("Password doesn't Match", "info");
      } else {
        await dispatch(setload_Screen(true));
        const User = {
          iUserId: _user.iUserId,
          strName: _user.strName,
          iGender: Number(_user.iGender),
          dpDOB: _user.dpDOB,
          strAdd1: _user.strAdd1,
          strAdd2: _user.strAdd2,
          strAdd3: _user.strAdd3,
          strAdd4: _user.strAdd4,
          strPincode: _user.strPincode,
          iCountry: Number(_user.iCountry),
          istate: Number(_user.iState),
          strPhone1: _user.strPhone,
          strPhone2: "",
          strMobile1: _user.strMobile,
          strMobile2: "",
          strEmail: _user.strEmail,
          iStatus: Number(_user.iStatus),
          strRemarks: _user.strRemarks,
          strUserName: _user.strUserName,
          strPassword: _user.strPassword,
          dpDOJ: _user.dpDOJ,
          IsLogout: _user.IsLogout,
          iUserRole: _user.iRoleId,
          iClientId: Number(_user.iClientId),
          strMasterDB: _user.strMasterDB,
        };
        const parems = {
          iUserId: _user.iUserId,
          iRoleId: _user.iRoleId,
          iCurrUserId: 0,
          iScrCategId: _user.iScrCatId,
          strMasterDB: _user.strMasterDB,
          objUser: User,
          objEntMPM: _user.Permission,
          objEntSPM: _user.Permission,
          objEntTPM: _user.Permission,
          objEntRPM: _user.Permission,
          objEntCMPM: _user.Permission,
          objEntCLPM: _user.Permission,
        };
        await adminApp
          .post("UserMaster/InsUpd_UserMaster", parems)
          .then((Res) => {
            if (Res.data.strStatus === "Success") {
              CloseClick();
              AlertMessage(Res.data.strMessage, "success");
            } else {
              AlertMessage(Res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const ClearClick = async () => {
    await dispatch(clearclientuserinput());
  };
  const CloseClick = async () => {
    await dispatch(clearclientuserinput());
    await Load_Clients();
  };
  const View_State = async (CountryId) => {
    try {
      await adminApp
        .get(`UserMaster/Load_State/${CountryId}/${_user.strMasterDB}`)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await dispatch(
              updateUserState({ iCountry: CountryId, State: res.data })
            );
          } else {
            AlertMessage(res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    }
  };
  const DeleteUser = async () => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp
        .post(
          `UserMaster/Delete_User/${Number(_user.iClientId)}/${Number(
            _user.iUserId
          )}/${_user.strMasterDB}`
        )
        .then((Res) => {
          if (Res.data.strStatus === "Success") {
            CloseClick();

            AlertMessage(Res.data.strMessage, "success");
          } else {
            AlertMessage(Res.data.strMessage, "info");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const permissionOnchange = async (e, row) => {
    let Permission = JSON.parse(JSON.stringify(_user.Permission));
    Permission = Permission.map((dt) => {
      if (dt.iMasPerId && dt.iMasPerId === row.iMasPerId) {
        dt[e.target.name] = e.target.checked;
      } else if (dt.iPerId && dt.iPerId === row.iPerId) {
        dt[e.target.name] = e.target.checked;
      } else if (dt.iRptPerId && dt.iRptPerId === row.iRptPerId) {
        dt[e.target.name] = e.target.checked;
      } else if (dt.iCompPerId && dt.iCompPerId === row.iCompPerId) {
        dt[e.target.name] = e.target.checked;
      }
      return dt;
    });
    await dispatch(
      updateUserPerm({
        Permission: Permission,
        iScrCatId: _user.iScrCatId,
      })
    );
  };
  const PermissionAll_OnChange = async (e) => {
    let Permission = JSON.parse(JSON.stringify(_user.Permission));
    Permission = Permission.map((dt) => {
      dt[e.target.name] = e.target.checked;
      return dt;
    });
    await dispatch(
      updateUserPerm({
        Permission: Permission,
        iScrCatId: _user.iScrCatId,
      })
    );
    await dispatch(
      permissionallOnchange({
        name: e.target.name,
        val: e.target.checked,
      })
    );
  };
  const View_PermColumn = async (ScrId) => {
    let Columns = {
      IsMaster: false,
      IsTrans: false,
      IsCompany: false,
      IsReport: false,
      IsCompanyList: false,
    };
    switch (ScrId) {
      case 2:
      case 3:
      case 5:
      case 6:
        Columns.IsTrans = true;
        break;
      case 7:
      case 8:
        Columns.IsCompany = true;
        break;
      case 10:
        Columns.IsReport = true;
        break;
      case 11:
        Columns.IsCompanyList = true;
        break;
      default:
        Columns.IsMaster = true;
        break;
    }
    await dispatch(showcolumn(Columns));
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const PermissionColumns = [
    {
      Header: "S.No",
      accessor: "sNo",
      style: { justifyContent: "center" },
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Header: "SCREEN NAME",
      accessor: "strScrName",
      style: { justifyContent: "left" },
      show: _user.IsColumns.IsMaster || _user.IsColumns.IsTrans,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="isNew"
            checked={_user.IsChecked.isNew}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>New</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsMaster || _user.IsColumns.IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="isNew"
          checked={rowinfo.isNew}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="isView"
            checked={_user.IsChecked.isView}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>View</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="isView"
          checked={rowinfo.isView}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="isModify"
            checked={_user.IsChecked.isModify}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>Modify</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsMaster || _user.IsColumns.IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="isModify"
          checked={rowinfo.isModify}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="isExport"
            checked={_user.IsChecked.isExport}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>Export</span>
        </div>
      ),
      isHeaderCell: true,
      style: { justifyContent: "center" },
      minWidth: 100,
      maxWidth: 100,
      show: _user.IsColumns.IsMaster,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="isExport"
          checked={rowinfo.isExport}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name={_user.IsColumns.IsTrans ? "isCancel" : "isDelete"}
            checked={
              _user.IsColumns.IsTrans
                ? _user.IsChecked.isCancel
                : _user.IsChecked.isDelete
            }
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>DELETE</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsMaster || _user.IsColumns.IsTrans,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name={_user.IsColumns.IsTrans ? "isCancel" : "isDelete"}
          checked={
            _user.IsColumns.IsTrans ? rowinfo.isCancel : rowinfo.isDelete
          }
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="isPrint"
            checked={_user.IsChecked.isPrint}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>Print</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      show: _user.IsColumns.IsTrans,
      style: { justifyContent: "center" },
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="isPrint"
          checked={rowinfo.isPrint}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="isNOAccess"
            checked={_user.IsChecked.isNOAccess}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>No Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsMaster || _user.IsColumns.IsTrans,
      cell: ({ rowinfo }) => (
        <div>
          <input
            type="checkbox"
            name="isNOAccess"
            checked={rowinfo.isNOAccess}
            onChange={(e) => permissionOnchange(e, rowinfo)}
          />
        </div>
      ),
    },
    {
      Header: "Access",
      accessor: "strAccessName",
      show: _user.IsColumns.IsCompany,
      style: { justifyContent: "left" },
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="bIsVisible"
            checked={_user.IsChecked.bIsVisible}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>Access</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsCompany,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="bIsVisible"
          checked={rowinfo.bIsVisible}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: "REPORT NAME",
      accessor: "strRptName",
      style: { justifyContent: "left" },
      show: _user.IsColumns.IsReport,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="bShowReport"
            checked={_user.IsChecked.bShowReport}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>Show</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsReport,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          name="bShowReport"
          checked={rowinfo.bShowReport}
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
    {
      Header: "COMPANY NAME",
      accessor: "strCompName",
      style: { justifyContent: "left" },
      show: _user.IsColumns.IsCompanyList,
    },
    {
      Header: () => (
        <div className="flex-wrap1">
          <input
            type="checkbox"
            name="bShowCompany"
            checked={_user.IsChecked.bShowCompany}
            onChange={(e) => PermissionAll_OnChange(e)}
          />
          <span>Show</span>
        </div>
      ),
      isHeaderCell: true,
      minWidth: 100,
      maxWidth: 100,
      style: { justifyContent: "center" },
      show: _user.IsColumns.IsCompanyList,
      cell: ({ rowinfo }) => (
        <input
          type="checkbox"
          checked={rowinfo.bShowCompany}
          name="bShowCompany"
          onChange={(e) => permissionOnchange(e, rowinfo)}
        />
      ),
    },
  ];
  const userInputOnchange = async (e) => {
    await dispatch(clientuserInputOnchange(e));
  };
  return (
    <>
      {!_common.IsUpdate && <Admintable IsBackground={true} />}
      {_common.IsUpdate && (
        <>
          {!_user.IsUser && <Admintable IsBackground={true} />}
          <div className="card-wrapper">
            {_user.IsUser && (
              <div className="row">
                <div className="col-sm-12 col-md-3  mt-1">
                  <label>User Role</label> <span className="danger">*</span>
                  <CustomizedSelectoption
                    optionparem="isoptionShow"
                    showoption={_select.isoptionShow}
                    placeholder="Select User Role"
                    select_value={async (val) =>
                      await dispatch(
                        userInputOnchange({ name: "iRoleId", value: val })
                      )
                    }
                    btnname="User Role"
                    btnshow={true}
                    show_popup={(e) => {
                      dispatch(showhideuserrole(_user.IsRole));
                    }}
                    valueonly={true}
                    defaultval={_user.iRoleId}
                    displayName="strName"
                    disvalue="iRoleId"
                    arrydata={_user.UserRole}
                    EmptVal="User Role"
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Name</label> <span className="danger">*</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter User Name"
                    tabIndex={1}
                    value={_user.strName}
                    onChange={(e) => userInputOnchange(e)}
                    onClick={(e) => e.target.select()}
                    name="strName"
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>User Name</label> <span className="danger">*</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter User Name"
                    tabIndex={2}
                    value={_user.strUserName}
                    onChange={(e) => userInputOnchange(e)}
                    onClick={(e) => e.target.select()}
                    name="strUserName"
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Password</label> <span className="danger">*</span>
                  <input
                    type="Password"
                    className="form-control"
                    placeholder="Enter Password"
                    tabIndex={3}
                    value={_user.strPassword}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    name="strPassword"
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Confirm Password</label>
                  <span className="danger">*</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Password"
                    tabIndex={4}
                    name="strConfirmPassword"
                    value={_user.strConfirmPassword}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    onBlur={(e) => {
                      if (e.target.value !== _user.strPassword) {
                        AlertMessage("Password Doesn't Match", "info");
                      }
                    }}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    tabIndex={8}
                    name="dpDOB"
                    value={_user.dpDOB}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Date of Joining</label>
                  <input
                    type="date"
                    className="form-control"
                    tabIndex={8}
                    name="dpDOJ"
                    value={_user.dpDOJ}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-2">
                  <label>Gender</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow1"
                    showoption={_select.isoptionShow1}
                    placeholder="Select Gender"
                    select_value={(val) =>
                      dispatch(
                        userInputOnchange({ name: "iGender", value: val })
                      )
                    }
                    btnname="Country"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={_user.iGender}
                    displayName="strGenderName"
                    disvalue="iGenderId"
                    arrydata={_user.Gender}
                    disabled={false}
                    EmptVal="Gender"
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Door/Office No</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Door/Office No"
                    tabIndex={5}
                    name="strAdd1"
                    value={_user.strAdd1}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Street/Area</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Street/Area"
                    tabIndex={6}
                    name="strAdd2"
                    value={_user.strAdd2}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Village/Town</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Village/Town"
                    tabIndex={7}
                    name="strAdd3"
                    value={_user.strAdd3}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    tabIndex={8}
                    name="strAdd4"
                    value={_user.strAdd4}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Pincode</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Pincode"
                    tabIndex={8}
                    name="strPincode"
                    value={_user.strPincode}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-2">
                  <label>
                    Country <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow2"
                    showoption={_select.isoptionShow2}
                    placeholder="Select Country"
                    select_value={(val) => View_State(val)}
                    btnname="Country"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={_user.iCountry}
                    displayName="strCountryName"
                    disvalue="iCountryId"
                    arrydata={_user.Country}
                    disabled={false}
                    EmptVal="Country "
                  />
                </div>
                <div className="col-md-3 col-sm-6 mt-2">
                  <label>
                    State <span className="danger">*</span>
                  </label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow3"
                    showoption={_select.isoptionShow3}
                    placeholder="Select State"
                    select_value={(val) => {
                      dispatch(
                        userInputOnchange({ name: "iState", value: val })
                      );
                    }}
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={_user.iState}
                    displayName="strSName"
                    disvalue="iStateId"
                    arrydata={_user.State}
                    disabled={false}
                    EmptVal="State "
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Mobile</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile No"
                    tabIndex={8}
                    name="strMobile"
                    value={_user.strMobile}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Phone No"
                    tabIndex={8}
                    name="strPhone"
                    value={_user.strPhone}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Email"
                    tabIndex={8}
                    name="strEmail"
                    value={_user.strEmail}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Remark</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Remark"
                    name="strRemarks"
                    tabIndex={8}
                    value={_user.strRemarks}
                    onClick={(e) => e.target.select()}
                    onChange={(e) => userInputOnchange(e)}
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6 mb-2">
                  <label>Status</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow4"
                    showoption={_select.isoptionShow4}
                    placeholder="Select Status"
                    select_value={(val) =>
                      dispatch(
                        userInputOnchange({ name: "iStatus", value: val })
                      )
                    }
                    btnname="State"
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={_user.iStatus}
                    displayName="status"
                    disvalue="statusId"
                    arrydata={_user.Status}
                    disabled={false}
                    EmptVal="Status "
                  />
                </div>
                <div className="col-md-3 col-sm-12 mt-1">
                  <label>Screen</label>
                  <CustomizedSelectoption
                    optionparem="isoptionShow5"
                    showoption={_select.isoptionShow5}
                    placeholder="Select Screen"
                    select_value={(val) =>
                      Load_UserPermssion(val, _user.iUserId)
                    }
                    btnname=""
                    btnshow={false}
                    show_popup={""}
                    valueonly={true}
                    defaultval={_user.iScrCatId}
                    displayName="strScrCatName"
                    disvalue="iScrCatId"
                    arrydata={_user.Screen}
                    EmptVal="Screen"
                  />
                </div>
                <div className="col-sm-12 col-md-3 mt-1">
                  <label>Logout User</label>
                  <input
                    type="checkbox"
                    className="form-check"
                    placeholder="Enter Remark"
                    name="IsLogout"
                    tabIndex={8}
                    checked={_user.IsLogout}
                    onChange={(e) =>
                      userInputOnchange({
                        name: "IsLogout",
                        value: e.target.checked,
                      })
                    }
                    autoComplete="off"
                    spellCheck={false}
                  />
                </div>
                <div className="p-2">
                  <ReactTable
                    columns={PermissionColumns}
                    data={_user.Permission}
                    minRows={5}
                    row_click={(rowInfo, Index) => {}}
                    row_doubleclick={(rowInfo, Index) => {}}
                    background={true}
                    className="full-table"
                  />
                </div>
              </div>
            )}
            {_user.IsRole && <UserRole />}
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                ClearClick();
              }}
            >
              New User&nbsp;<i className="bx bxs-user-plus"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(_user.iRoleId) === 0) {
                  AlertMessage("Please Enter UserRole", "info");
                } else if (_user.strUserName === "") {
                  AlertMessage("Please Enter UserName", "info");
                } else if (_user.strPassword === "") {
                  AlertMessage("Please Enter Password", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: Save_User,
                      Question: "Do You Want to Update User?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              {Number(_user.iUserId) === 0 ? "Save" : "Update"}&nbsp;
              <i className="bx bxs-save"></i>
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(_user.iClientId) === 0) {
                  AlertMessage("Please Select User", "info");
                } else if (Number(_user.iUserId) === 1) {
                  AlertMessage("Admin User Can't Delete ", "error");
                } else if (Number(_user.iUserId) === 0) {
                  AlertMessage("Please Select User", "info");
                } else {
                  dispatch(
                    Show_MsgBox({
                      Button: DeleteUser,
                      Question: "Do You Want to Delete User?",
                      isdialog: true,
                    })
                  );
                }
              }}
            >
              Delete&nbsp;<i className="bx bx-trash-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={CloseClick}>
              Close&nbsp;<i className="fas fa-times"></i>
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default Usermaster;

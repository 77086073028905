import { useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import {
  addotp,
  cancelverification,
  showOTPVerification,
} from "./features/Common";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";

function OTPValidation(props) {
  const dispatch = useDispatch();
  const _user = useSelector((store) => store.Common.user);
  const [otp1, setotp1] = useState("");
  const [otp2, setotp2] = useState("");
  const [otp3, setotp3] = useState("");
  const [otp4, setotp4] = useState("");
  const [otp5, setotp5] = useState("");
  const [otp6, setotp6] = useState("");
  const [IsShow, setIsShow] = useState(false);
  const [IsDisable, setIsDisable] = useState(false);

  const Update_Otp = () => {
    if (
      otp1.length > 0 &&
      otp2.length > 0 &&
      otp3.length > 0 &&
      otp4.length > 0 &&
      otp5.length > 0 &&
      otp6.length > 0
    )
      dispatch(addotp(`${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`));
    else
      dispatch(
        Show_MsgBox({
          Message: "Please Enter Valida OTP",
          Type: "info",
          isMsg: true,
        })
      );
  };
  const generateOTP = async (type) => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        Otp: "",
        strUserName: _user.strUserName,
        strotptype: type,
      };
      await adminApp
        .post(`UserMaster/SendEmailAsync`, parems)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await dispatch(showOTPVerification(type));
            setIsDisable(true);
            FiveMinuteTimeout();
          } else await AlertMessage(res.data.strMessage, "error");
        });
    } catch (err) {
      await AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const FiveMinuteTimeout = () => {
    const timer = setTimeout(() => {
      setIsShow(true);
      setIsDisable(false);
    }, 300000); // 300000ms = 5 minutes
    return () => clearTimeout(timer);
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-body">
          <div className="title">
            <h3>OTP VERIFICATION</h3>
            <p className="info">An otp has been sent to Admin E-Mail</p>
          </div>
          <div className="otp-input-fields">
            <input
              type="text"
              className="otp_digit"
              id="otp1"
              onChange={(e) => {
                setotp1(e.target.value);
                $("#otp2").focus();
              }}
              value={otp1}
              spellCheck={false}
              autoComplete="off"
            />
            <input
              type="text"
              className="otp_digit"
              id="otp2"
              onChange={(e) => {
                setotp2(e.target.value);
                $("#otp3").focus();
              }}
              value={otp2}
              spellCheck={false}
              autoComplete="off"
            />
            <input
              type="text"
              className="otp_digit"
              id="otp3"
              spellCheck={false}
              autoComplete="off"
              onChange={(e) => {
                setotp3(e.target.value);
                $("#otp4").focus();
              }}
              value={otp3}
            />
            <input
              type="text"
              className="otp_digit"
              id="otp4"
              spellCheck={false}
              autoComplete="off"
              onChange={(e) => {
                setotp4(e.target.value);
                $("#otp5").focus();
              }}
              value={otp4}
            />
            <input
              type="text"
              className="otp_digit"
              id="otp5"
              spellCheck={false}
              autoComplete="off"
              onChange={(e) => {
                setotp5(e.target.value);
                $("#otp6").focus();
              }}
              value={otp5}
            />
            <input
              type="text"
              className="otp_digit"
              id="otp6"
              spellCheck={false}
              autoComplete="off"
              onChange={(e) => {
                setotp6(e.target.value);
              }}
              onBlur={(e) => Update_Otp()}
              value={otp6}
            />
          </div>
        </div>
        <div className="popup-footer">
          <div className="popup-btn-group">
            {IsShow && (
              <button
                className="btn-fabgreen"
                onClick={(e) => generateOTP()}
                disabled={IsDisable}
              >
                Resent OTP
              </button>
            )}
            <button
              className="btn-fabgreen"
              onClick={(e) => props.Verifyclick()}
            >
              Verify & Submit
            </button>
            <button
              className="btn-fabgreen"
              onClick={(e) => dispatch(cancelverification())}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTPValidation;

import React, { useState } from "react";
import {
  loadclientuser,
  reassignClientdata,
  reassignuserdata,
  viewCompany,
  viewEnquiry,
  viewNews,
  viewrestoreClient,
  viewrestoreCompany,
  viewScriptClient,
  viewuserclient,
} from "./features/Common";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
function Admintable(props) {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);

  const [iSelected, setiSelected] = useState(0);

  const Table_Style = (dt, Item, Index, Selected) => {
    const Style = {
      textAlign: dt.textAlign,
      whiteSpace: "wrap",
      color: props.IsBackground
        ? Index === Selected
          ? "white"
          : dt.ColorCondition
          ? Item[dt.colName] !== dt.ColorCondition
            ? "red"
            : dt.color
          : dt.color
        : dt.ColorCondition
        ? Item[dt.colName] !== dt.ColorCondition
          ? "red"
          : dt.color
        : dt.color,
    };
    if (dt.width.includes("%") || dt.width.includes("auto")) {
      Style.width = dt.width;
      Style.minWidth = "130px";
    } else {
      Style.width = dt.width + "px";
      Style.minWidth = dt.width + "px";
      Style.maxWidth = dt.width + "px";
    }
    return Style;
  };
  const View_Data = async (data) => {
    if (_common.permission.IsModify) {
      switch (_common.iScrId) {
        case 1:
          await dispatch(reassignClientdata(data));
          break;
        case 2:
          await View_AdminUser(data);
          break;
        case 3:
          await dispatch(viewNews(data));
          break;
        case 4:
          await dispatch(viewEnquiry(data));
          break;
        case 5:
          if (_common.IsUpdate) await dispatch(loadclientuser(data));
          else await dispatch(viewuserclient(data));
          break;
        case 8:
          if (_common.IsUpdate) await dispatch(viewCompany(data));
          else await dispatch(viewScriptClient(data));
          break;
        case 9:
          if (_common.IsUpdate) await dispatch(viewrestoreCompany(data));
          else await dispatch(viewrestoreClient(data));
          break;
        default:
          break;
      }
    } else AlertMessage("Not Allowed to Modify", "info");
  };
  const View_AdminUser = async (data) => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp
        .get(`UserMaster/View_AdminUser/${data.iUserId}`)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            await dispatch(reassignuserdata(res.data));
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      await AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="table-admin">
      <table className="option-list">
        <thead>
          <tr>
            {_common.Columns.map((ht, Index) => (
              <th key={Index}>{ht.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {_common.Data.map((Item, trIndex) => (
            <tr
              key={trIndex}
              tabIndex={trIndex}
              onKeyDown={(e) => {
                const arrlength = _common.Data.length - 1;
                if (e.key === "ArrowDown") {
                  if (trIndex === arrlength) setiSelected(0);
                  else setiSelected(iSelected + 1);
                }
                if (e.key === "ArrowUp") {
                  if (trIndex === 0) setiSelected(arrlength);
                  else setiSelected(iSelected - 1);
                }
                if (e.key === "Enter") View_Data(_common.Data[iSelected]);
              }}
              onClick={(e) => {
                setiSelected(trIndex);
              }}
              onDoubleClick={(e) => {
                View_Data(_common.Data[trIndex]);
              }}
              style={{
                background: props.IsBackground
                  ? trIndex === iSelected
                    ? "darkgreen"
                    : "#fff"
                  : "#fff",
              }}
            >
              {_common.Columns.map((dt, Index) =>
                dt.cell ? (
                  <td
                    key={Index}
                    style={Table_Style(dt, Item, trIndex, iSelected)}
                  >
                    <dt.cell user={Item} />
                  </td>
                ) : (
                  <td
                    key={Index}
                    style={Table_Style(dt, Item, trIndex, iSelected)}
                  >
                    {Item[dt.colName].toString()}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Admintable;

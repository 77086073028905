import { Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";
import Messagedialogbox from "./Messagedialogbox";
function ProtectedRoute() {
  return (
    <div className="jb_app">
      <SideMenu />
      <main>
        <Outlet />
      </main>
      <Messagedialogbox />
    </div>
  );
}
export default ProtectedRoute;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Show_MsgBox, setload_Screen } from "./features/Message";
import adminApp, { Backup_Data } from "./Utility";
import Admintable from "./Admintable";
import $ from "jquery";
import CustomizedSelectoption from "./CustomizedSelectoption";
import {
  clearrestore,
  clearrestoreselectfile,
  initialCommonparems,
  load_clientcompany,
  LoadDefaultstates,
  selectdbtype,
  selectrestorefile,
  ValidateUser_Permission,
} from "./features/Common";
function RestoreMaster() {
  const dispatch = useDispatch();
  const _Restore = useSelector((store) => store.Common.Restore);
  const _common = useSelector((store) => store.Common);
  const _select = useSelector((state) => state.SelectOption.parems);

  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_Clients").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 9,
              Data: res.data.Clients,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(9),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  useEffect(() => {
    const View_Client = async () => {
      try {
        if (_Restore.iClientId > 0) {
          await dispatch(setload_Screen(true));
          await adminApp
            .get(`CompanyMaster/Load_ClientCompany/${_Restore.iClientId}`)
            .then(async (res) => {
              if (res.data.strStatus === "Success") {
                await dispatch(
                  load_clientcompany({
                    Data: res.data.Company,
                    Columns: res.data.Columns,
                  })
                );
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        }
      } catch (err) {
        AlertMessage(err.message, "error");
      } finally {
        await dispatch(setload_Screen(false));
      }
    };
    View_Client();
  }, [_Restore.iClientId]);

  const Restore_Database = async () => {
    try {
      await progress(0);
      const BackupData = await Backup_Data({
        Name: _Restore.strClientName,
        dispatch: dispatch,
        Msdb: _Restore.strMasterDB,
      });
      if (BackupData) {
        await dispatch(setload_Screen(true));
        await progress(1);
        const formdata = new FormData();
        formdata.append("File", _Restore.File);
        formdata.append("FileName", _Restore.FileName);
        await progress(2);
        await adminApp
          .post("DBMaster/Restore_Database", formdata)
          .then(async (res) => {
            if (res.data.strStatus === "Success") {
              await progress(3);
              AlertMessage(res.data.strMessage, "success");
            } else {
              AlertMessage(res.data.strMessage, "error");
            }
          });
      }
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const progress = (tabindex) => {
    let linecomp = document.getElementById("percentcomp");
    let linepen = document.getElementById("percentpen");
    var stepsparent = $(".steps");
    linepen.style.width = stepsparent.width();
    var steps = $(".steps .step");
    linecomp.style.width = steps[tabindex].offsetLeft + "px";
    $(`.steps .step:eq(${tabindex})`).addClass("completed");
  };
  const Clear_Click = async () => {
    await dispatch(clearrestore());
    await Load_Clients();
    $(`.steps`).removeClass("completed");
  };
  return (
    <>
      {!_common.IsUpdate && <Admintable IsBackground={true} />}
      {_common.IsUpdate && (
        <>
          <div className="card-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label>Database Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow1"
                  showoption={_select.isoptionShow1}
                  placeholder="Select Database type"
                  select_value={(val) => dispatch(selectdbtype(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={_Restore.dbType}
                  displayName="Type"
                  disvalue="Type"
                  arrydata={_Restore.ScrType}
                  disabled={false}
                  EmptVal="Database type"
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>Select Script File</label>
                <div className="custom-file-upload">
                  <label htmlFor="file-upload" className="custom-file-label">
                    {_Restore.FileName.length > 0
                      ? _Restore.FileName
                      : "Choose Script File"}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => {
                      dispatch(
                        selectrestorefile({
                          File: e.target.files[0],
                          FileName: e.target.files[0].name.split(".")[0],
                        })
                      );
                    }}
                    accept=".sql"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 mt-4">
                <button
                  className="btn-fabgreen"
                  onClick={async (e) => {
                    var file = document.getElementById("file-upload");
                    file.value = "";
                    await dispatch(clearrestoreselectfile());
                  }}
                >
                  Clear&nbsp;<i className="fa-solid fa-broom"></i>
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <h5 className="restorcon">Restore Organization</h5>
              <div className="resprogress">
                <div className="steps">
                  <div className="step">
                    <span>
                      <i className="fa-regular fa-circle-check"></i>
                    </span>
                    Verification
                  </div>
                  <div className="step">
                    <span>
                      <i className="fa-solid fa-cloud-arrow-down"></i>
                    </span>
                    Backup
                  </div>
                  <div className="step">
                    <span>
                      <i className="fa-solid fa-trash"></i>
                    </span>
                    Delete
                  </div>
                  <div className="step">
                    <span>
                      <i className="fa-solid fa-cloud-arrow-up"></i>
                    </span>
                    Restore
                  </div>
                </div>
                <div id="percentpen"></div>
                <div id="percentcomp"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label>Client Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Client Name"
                  defaultValue={_Restore.strClientName}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Master Database</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Master Database"
                  defaultValue={_Restore.strMasterDB}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Comapany Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  defaultValue={_Restore.strCompanyName}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Financial Year</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Financial Year"
                  defaultValue={_Restore.strYear}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Company Database</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Database"
                  defaultValue={_Restore.strCompanyDB}
                  disabled
                />
              </div>
            </div>
            <div className="mt-2">
              <Admintable IsBackground={true} />
            </div>
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => {
                if (Number(_Restore.iClientId) === 0)
                  AlertMessage("Please Select Client", "info");
                else if (_Restore.FileName.length === 0)
                  AlertMessage("Please Select Backup", "info");
                else if (_Restore.dbType.length === 0)
                  AlertMessage("Please Select Database Type", "info");
                else if (
                  _Restore.dbType === "Master" &&
                  _Restore.strMasterDB.length === 0
                )
                  AlertMessage("Please Select Client Database", "info");
                else if (
                  _Restore.dbType === "Company" &&
                  _Restore.strCompanyDB.length === 0
                )
                  AlertMessage("Please Select Company", "info");
                else if (
                  _Restore.dbType === "Master" &&
                  _Restore.strMasterDB !== _Restore.FileName
                )
                  AlertMessage("Please Select Valid Backup File", "info");
                else if (
                  _Restore.dbType === "Company" &&
                  _Restore.strCompanyDB !== _Restore.FileName
                )
                  AlertMessage(
                    "Please Select Valid Financial Year Or Backup File",
                    "info"
                  );
                else
                  dispatch(
                    Show_MsgBox({
                      Button: Restore_Database,
                      Question: "Do You Want to Restore Data?",
                      isdialog: true,
                    })
                  );
              }}
              disabled={!_common.permission.IsRestore}
            >
              Restore&nbsp;<i className="fa-solid fa-arrows-rotate"></i>
            </button>
            <button className="btn-fabgreen" onClick={() => Clear_Click()}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default RestoreMaster;

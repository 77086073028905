import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import Admintable from "./Admintable";
import {
  clearNews,
  initialCommonparems,
  LoadDefaultstates,
  newsInputOnchange,
  ValidateUser_Permission,
} from "./features/Common";
function NewsMaster() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const _news = useSelector((store) => store.Common.news);

  useEffect(() => {
    Load_News();
  }, []);
  const Load_News = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_News").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 3,
              Data: res.data.EntNews,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(3),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Save_News = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iNewsId: _news.iNewsId,
        strNews: _news.strNews,
        Status: Number(_news.Status),
      };
      await adminApp.post("ClientMaster/InsUpd_News", parems).then((res) => {
        if (res.data.strStatus === "Success") {
          Clear_Click();
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_News = async () => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp
        .post(`ClientMaster/Delete_News/${Number(_news.iNewsId)}`)
        .then((res) => {
          if (res.data.strStatus === "Success") {
            Clear_Click();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "info");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Clear_Click = async () => {
    await dispatch(clearNews());
    await Load_News();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };

  return (
    <>
      <div className="card-wrapper">
        <div className="row">
          <div className="col-sm-12 col-md-8 mt-1">
            <label>News</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter News"
              name="strNews"
              tabIndex={3}
              value={_news.strNews}
              onChange={(e) => dispatch(newsInputOnchange(e))}
              style={{ minHeight: "150px" }}
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="col-sm-12 col-md-4 mt-1">
            <label>Status</label>
            <input
              type="checkbox"
              className="form-check"
              checked={_news.Status}
              onChange={() =>
                dispatch(
                  newsInputOnchange({
                    name: "Status",
                    value: !_news.Status,
                  })
                )
              }
            />
          </div>
          <div className="mt-2">
            <Admintable IsBackground={true} />
          </div>
        </div>
      </div>
      <div className="btn-section">
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            dispatch(
              Show_MsgBox({
                Button: Save_News,
                Question: "Do You Want to Update News?",
                isdialog: true,
              })
            );
          }}
          disabled={!_common.permission.IsCreate}
        >
          Save&nbsp;<i className="bx bxs-save"></i>
        </button>
        <button
          className="btn-fabgreen"
          onClick={() => {
            if (_news.iNewsId === 0) AlertMessage("Please Select News", "info");
            else
              dispatch(
                Show_MsgBox({
                  Button: Delete_News,
                  Question: "Do You Want to Delete News?",
                  isdialog: true,
                })
              );
          }}
          disabled={!_common.permission.IsDelete}
        >
          Delete&nbsp;<i className="bx bx-trash-alt"></i>
        </button>
        <button className="btn-fabgreen" onClick={() => Clear_Click()}>
          Clear&nbsp;<i className="fa-solid fa-broom"></i>
        </button>
      </div>
    </>
  );
}

export default NewsMaster;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminLogin from "./components/AdminLogin";
import AdminMaster from "./components/AdminMaster";
import AvailableDatabase from "./components/Availabledatabase";
import ClientMaster from "./components/ClientMaster";
import EnquiryMaster from "./components/EnquiryMaster";
import ErrorLogs from "./components/ErrorLogs";
import NewsMaster from "./components/NewsMaster";
import RestoreMaster from "./components/RestoreMaster";
import ScriptMaster from "./components/ScriptMaster";
import UserDatabase from "./components/UserDatabase";
import Usermaster from "./components/Usermaster";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/admin/Admin-Master" element={<AdminMaster />} />
          <Route
            path="/admin/AvailableDatabase"
            element={<AvailableDatabase />}
          />
          <Route path="/admin/Client-Info/:Id?" element={<ClientMaster />} />
          <Route path="/admin/EnquiryMaster" element={<EnquiryMaster />} />
          <Route path="/admin/ErrorLogs" element={<ErrorLogs />} />
          <Route path="/admin/NewsMaster" element={<NewsMaster />} />
          <Route path="/admin/RestoreMaster" element={<RestoreMaster />} />
          <Route path="/admin/ScriptMaster" element={<ScriptMaster />} />
          <Route path="/admin/UserDatabase" element={<UserDatabase />} />
          <Route path="/admin/Usermaster" element={<Usermaster />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

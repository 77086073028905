import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp, { API_URL, DecryptData, Logout_User } from "./Utility";
import { useNavigate } from "react-router-dom";
import { LoadDefaultstates } from "./features/Common";
import moment from "moment";
function SideMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const [arrEntPer, setarrEntPer] = useState([]);
  const [IsLogOut, setIsLogOut] = useState(false);

  useEffect(() => {
    Load_Permissions();
  }, []);
  useEffect(() => {
    if (IsLogOut) {
      Logout_User();
    }
  }, [IsLogOut]);

  const Load_Permissions = async () => {
    try {
      let authparem = await DecryptData(localStorage.jbctlparm);
      await dispatch(setload_Screen(true));
      await adminApp
        .get(`UserMaster/Admin_AutoLogout/${Number(authparem.User)}`)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            setIsLogOut(res.data.AutoLogOut);
            setarrEntPer(JSON.parse(authparem.mnper));
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const MenuClick = (Index) => {
    $(`.nav-item`).removeClass("active");
    $(`.nav-item:eq(${Index})`).addClass("active");
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const menuIcons = (ScrId) => {
    switch (ScrId) {
      case 1:
        return <i className="bx bx-buildings"></i>;
      case 2:
        return <i className="bx bx-user"></i>;
      case 3:
        return <i className="bx bx-news"></i>;
      case 4:
        return <i className="fas fa-envelope"></i>;
      case 5:
        return <i className="fa-solid fa-users"></i>;
      case 6:
        return <i className="fa-solid fa-server"></i>;
      case 7:
        return <i className="bx bxs-hdd"></i>;
      case 8:
        return <i className="fa-solid fa-scroll"></i>;
      case 9:
        return <i className="bx bx-cloud-upload"></i>;
      case 10:
        return <i className="bx bx-error"></i>;
      default:
        break;
    }
  };
  const Backup_Server = async () => {
    try {
      await dispatch(setload_Screen(true));
      await fetch(`${API_URL}DBMaster/Backup_Server`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.jbadmparetkau}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.blob())
        .then((res) => {
          const url = window.URL.createObjectURL(res);
          const link = document.createElement("a");
          link.href = url;
          const now = moment();
          const curr_date = now.format("D-MMM-YYYY");
          const FileName = `JustBill_${curr_date}.zip`;
          link.setAttribute("download", FileName);
          link.click();
        })
        .catch(async (err) => {
          await dispatch(
            Show_MsgBox({ Message: err.stack, Type: "error", isMsg: true })
          );
        });
    } catch (err) {
      await dispatch(
        Show_MsgBox({ Message: err.message, Type: "error", isMsg: true })
      );
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Restart_App = async () => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Restart_App").then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Shoutdown_App = async () => {
    try {
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Restart_App").then((res) => {
        if (res.data.strStatus === "Success") {
          AlertMessage(res.data.strMessage, "success");
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  return (
    <>
      <header className=" top-menu">
        <h1>{_common.ScrName}</h1>
        <div className="header-icon">
          {_common.permission.IsBackup && (
            <span
              className="hicon"
              onClick={(e) =>
                dispatch(
                  Show_MsgBox({
                    Button: Backup_Server,
                    Question: `Do You Want to Backup Server Data?`,
                    isdialog: true,
                  })
                )
              }
            >
              <i className="fa-solid fa-server"></i>
              <span className="tooltip bottom">Backup Server</span>
            </span>
          )}
          {_common.permission.IsBackup && (
            <span
              className="hicon"
              onClick={(e) =>
                dispatch(
                  Show_MsgBox({
                    Button: Restart_App,
                    Question: `Do You Want to Restart App?`,
                    isdialog: true,
                  })
                )
              }
            >
              <i className="fa-solid fa-rotate-right"></i>
              <span className="tooltip bottom">Restart App</span>
            </span>
          )}
          {_common.permission.IsBackup && (
            <span
              className="hicon"
              onClick={(e) =>
                dispatch(
                  Show_MsgBox({
                    Button: Shoutdown_App,
                    Question: `Do You Want to Shoutdown App?`,
                    isdialog: true,
                  })
                )
              }
            >
              <i className="fa-solid fa-power-off"></i>
              <span className="tooltip bottom">Shoutdown App</span>
            </span>
          )}
          <span className="hicon" onClick={(e) => Logout_User()}>
            <i className="bx bx-log-out"></i>
            <span className="tooltip bottom">SignOut</span>
          </span>
        </div>
      </header>
      <nav>
        <div className="btn-bars">
          <span
            className="fas fa-bars third"
            onClick={(e) => {
              $(".btn-bars").toggleClass("click");
              $("nav").toggleClass("show");
            }}
          ></span>
        </div>
        <div className="nav-header">
          <h3>JustBill C-Panel</h3>
        </div>
        <ul>
          {arrEntPer.map((per, Index) => (
            <li
              key={Index}
              className={Index === 0 ? "nav-item active" : "nav-item"}
            >
              <span
                onClick={async () => {
                  MenuClick(Index);
                  await Load_Permissions();
                  if (window.location.pathname !== `/admin${per.menuPath}`)
                    navigate(`/admin${per.menuPath}`);
                }}
              >
                {menuIcons(per.scrId)}
                {per.scrName}
              </span>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
export default SideMenu;

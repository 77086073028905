import { createSlice } from "@reduxjs/toolkit";
import { DecryptData } from "../Utility";
export const CommonSlice = createSlice({
  name: "Parems",
  initialState: {
    iScrId: 0,
    ScrName: "",
    IsUpdate: false,
    IsOTP: false,
    OtpType: "New",
    OTP: "",
    client: {
      iClientId: "",
      iMsDBId: 0,
      iCompDBId: 0,
      strClientName: "",
      strCompanyName: 0,
      strMasterDB: "",
      strCompanyDB: 0,
      logedinDevice: 0,
      MaxDevice: 0,
      iYearId: 0,
      strAdd1: "",
      strAdd2: "",
      strAdd3: "",
      strAdd4: "",
      strPinCode: "",
      strMobile: "",
      strEmail: "",
      strGST: "",
      ActivationDate: new Date().toISOString().split("T")[0],
      ExpiryDate: new Date().toISOString().split("T")[0],
      Subscription: "",
    },
    user: {
      iUserId: 0,
      iRole: 0,
      strName: "",
      strUserName: "",
      strPassword: "",
      strConfirmPassword: "",
      strDoorNo: "",
      strStreet: "",
      strAreaName: "",
      strPincode: "",
      strCity: "",
      strMobile: "",
      iStatus: 1,
      IsLogout: false,
    },
    news: {
      iNewsId: 0,
      strNews: "",
      Status: 0,
    },
    enquiry: {
      iEnquiryId: 0,
      strClientName: "",
      strAdd1: "",
      strAdd2: "",
      strAdd3: "",
      strAdd4: "",
      strPinCode: "",
      strMobile: "",
      strEmail: "",
      strGST: "",
      strUserName: "",
      strPassword: "",
      strConfPassword: "",
      strRefferel: "",
      iStatus: 1,
      ActiveDate: new Date().toISOString().split("T")[0],
      EnquiryDate: new Date().toISOString().split("T")[0],
      IsNewClient: false,
    },
    permission: {
      IsCreate: false,
      IsModify: false,
      IsDelete: false,
      IsView: false,
      IsBackup: false,
      IsRestore: false,
      IsNoAccess: false,
    },
    script: {
      iClientId: 0,
      strClientName: "",
      strCompanyName: "",
      strYear: "",
      strCompanyDB: "",
      strMasterDB: "",
      IsMaster: false,
      Company: [],
      scripttype: "Company",
      Script: [{ Type: "Master" }, { Type: "Company" }],
      FileName: "",
      File: [],
      IsServer: false,
    },
    Restore: {
      iClientId: 0,
      strClientName: "",
      strCompanyName: "",
      strYear: "",
      strCompanyDB: "",
      strMasterDB: "",
      dbType: "Company",
      ScrType: [{ Type: "Master" }, { Type: "Company" }],
      FileName: "",
      File: [],
      Company: [],
    },
    clientuser: {
      iClientId: 0,
      strMasterDB: "",
      IsUser: false,
      IsLogout: false,
      iUserId: 0,
      strName: "",
      dpDOB: new Date().toISOString().split("T")[0],
      dpDOJ: new Date().toISOString().split("T")[0],
      iGender: 1,
      strUserName: "",
      strPassword: "",
      strConfirmPassword: "",
      iRoleId: 0,
      strAdd1: "",
      strAdd2: "",
      strAdd3: "",
      strAdd4: "",
      strPincode: "",
      iCountry: 1,
      iState: 32,
      strPhone: "",
      strMobile: "",
      strEmail: "",
      iStatus: 1,
      strRemarks: "",
      iScrCatId: 1,
      IsColumns: {
        IsMaster: false,
        IsTrans: false,
        IsCompany: false,
        IsReport: false,
        IsCompanyList: false,
      },
      IsChecked: {
        isNew: false,
        isView: false,
        isModify: false,
        isExport: false,
        isDelete: false,
        isCancel: false,
        isPrint: false,
        isNoAccess: false,
        bIsVisible: false,
        bShowReport: false,
        bShowCompany: false,
        isShow: false,
      },
      User: [],
      UserRole: [],
      Screen: [],
      State: [],
      Country: [],
      Status: [],
      Gender: [],
      Permission: [],
      IsRole: false,
    },
    Data: [],
    Columns: [],
    Status: [],
    Role: [],
    Permission: [],
  },
  reducers: {
    initialCommonparems: (state, { payload }) => {
      state.iScrId = payload.iScrId;
      state.Data = payload.Data;
      state.Columns = payload.Columns;
      state.Status = payload.Status ? payload.Status : [];
      state.Role = payload.Role ? payload.Role : [];
      state.permission = payload.permission;
      state.ScrName = payload.permission.ScrName;
    },
    initalscreenname: (state, { payload }) => {
      state.ScrName = payload;
    },
    clientInputOnchange: (state, { payload }) => {
      if (payload.target !== undefined)
        state.client[payload.target.name] = payload.target.value;
      else state.client[payload.name] = payload.value;
    },
    reassignClientdata: (state, { payload }) => {
      const client = payload;
      state.client = {
        iClientId: client.clientId,
        iMsDBId: client.masterDB,
        iCompDBId: 0,
        strClientName: client.clientName,
        strCompanyName: 0,
        strMasterDB: client.strMasterDB,
        strCompanyDB: 0,
        iYearId: 0,
        logedinDevice: client.loginDevice,
        MaxDevice: client.maxDevice,
        strAdd1: client.add1,
        strAdd2: client.add2,
        strAdd3: client.add3,
        strAdd4: client.add4,
        strPinCode: client.pinCode,
        strMobile: client.mobile,
        strEmail: client.email,
        strGST: client.gst,
        ActivationDate: client.activationDate,
        ExpiryDate: client.expiryDate,
        Subscription: client.subscription,
      };
      state.IsUpdate = true;
      state.OtpType = "Update";
    },
    clearClientinput: (state) => {
      state.client = {
        iClientId: "",
        iMsDBId: 0,
        iCompDBId: 0,
        strClientName: "",
        strCompanyName: 0,
        strMasterDB: "",
        strCompanyDB: 0,
        iYearId: 0,
        logedinDevice: 0,
        MaxDevice: 0,
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        ActivationDate: new Date().toISOString().split("T")[0],
        ExpiryDate: new Date().toISOString().split("T")[0],
        Subscription: "",
      };
    },
    clearDefaultstates: (state) => {
      state.iScrId = 0;
      state.ScrName = "";
      state.IsUpdate = false;
      state.IsOTP = false;
      state.OtpType = "New";
      state.OTP = "";
      state.client = {
        iClientId: "",
        iMsDBId: 0,
        iCompDBId: 0,
        strClientName: "",
        strCompanyName: 0,
        strMasterDB: "",
        strCompanyDB: 0,
        logedinDevice: 0,
        MaxDevice: 0,
        iYearId: 0,
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        ActivationDate: new Date().toISOString().split("T")[0],
        ExpiryDate: new Date().toISOString().split("T")[0],
        Subscription: "",
      };
      state.user = {
        iUserId: 0,
        iRole: 0,
        strName: "",
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        strDoorNo: "",
        strStreet: "",
        strAreaName: "",
        strPincode: "",
        strCity: "",
        strMobile: "",
        iStatus: 1,
        IsLogout: false,
      };
      state.news = {
        iNewsId: 0,
        strNews: "",
        Status: 0,
      };
      state.enquiry = {
        iEnquiryId: 0,
        strClientName: "",
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        strUserName: "",
        strPassword: "",
        strConfPassword: "",
        strRefferel: "",
        iStatus: 1,
        ActiveDate: new Date().toISOString().split("T")[0],
        EnquiryDate: new Date().toISOString().split("T")[0],
        IsNewClient: false,
      };
      state.permission = {
        IsCreate: false,
        IsModify: false,
        IsDelete: false,
        IsView: false,
        IsBackup: false,
        IsRestore: false,
        IsNoAccess: false,
      };
      state.script = {
        iClientId: 0,
        strClientName: "",
        strCompanyName: "",
        strYear: "",
        strCompanyDB: "",
        strMasterDB: "",
        IsMaster: false,
        Company: [],
        scripttype: "Company",
        Script: [{ Type: "Master" }, { Type: "Company" }],
        FileName: "",
        File: [],
        IsServer: false,
      };
      state.Restore = {
        iClientId: 0,
        strClientName: "",
        strCompanyName: "",
        strYear: "",
        strCompanyDB: "",
        strMasterDB: "",
        dbType: "Company",
        ScrType: [{ Type: "Master" }, { Type: "Company" }],
        FileName: "",
        File: [],
        Company: [],
      };
      state.clientuser = {
        iClientId: 0,
        strMasterDB: "",
        IsUser: false,
        iUserId: 0,
        IsLogout: false,
        strName: "",
        dpDOB: new Date().toISOString().split("T")[0],
        dpDOJ: new Date().toISOString().split("T")[0],
        iGender: 1,
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        iRoleId: 0,
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPincode: "",
        iCountry: 1,
        iState: 32,
        strPhone: "",
        strMobile: "",
        strEmail: "",
        iStatus: 1,
        strRemarks: "",
        iScrCatId: 1,
        IsColumns: {
          IsMaster: false,
          IsTrans: false,
          IsCompany: false,
          IsReport: false,
          IsCompanyList: false,
        },
        IsChecked: {
          isNew: false,
          isView: false,
          isModify: false,
          isExport: false,
          isDelete: false,
          isCancel: false,
          isPrint: false,
          isNoAccess: false,
          bIsVisible: false,
          bShowReport: false,
          bShowCompany: false,
          isShow: false,
        },
        User: [],
        UserRole: [],
        Screen: [],
        State: [],
        Country: [],
        Status: [],
        Gender: [],
        Permission: [],
        IsRole: false,
      };
      state.Data = [];
      state.Columns = [];
      state.Status = [];
      state.Role = [];
      state.Permission = [];
    },
    userInputOnchange: (state, { payload }) => {
      if (payload.target !== undefined)
        state.user[payload.target.name] = payload.target.value;
      else state.user[payload.name] = payload.value;
    },
    reassignuserdata: (state, { payload }) => {
      const user = payload.User[0];
      state.user = {
        iUserId: user.iUserId,
        iRole: user.iRole,
        strName: user.strName,
        strUserName: user.strUserName,
        strPassword: user.strPassword,
        strConfirmPassword: user.strPassword,
        strDoorNo: user.strDoorNo,
        strStreet: user.strStreet,
        strAreaName: user.strAreaName,
        strPincode: user.strPincode,
        strCity: user.strCity,
        strMobile: user.strMobile,
        iStatus: user.iStatus,
        IsLogout: Boolean(user.iLogOut),
      };
      state.IsUpdate = true;
      state.OtpType = "Update";
      state.Permission = payload.Permission;
    },
    createNewuser: (state) => {
      state.user = {
        iUserId: 0,
        iRole: 0,
        strName: "",
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        strDoorNo: "",
        strStreet: "",
        strAreaName: "",
        strPincode: "",
        strCity: "",
        strMobile: "",
        iStatus: 1,
        IsLogout: false,
      };
      state.IsUpdate = true;
      state.OtpType = "New";
      state.Permission = [];
    },
    clearUserinput: (state) => {
      state.user = {
        iUserId: 0,
        iRole: 0,
        strName: "",
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        strDoorNo: "",
        strStreet: "",
        strAreaName: "",
        strPincode: "",
        strCity: "",
        strMobile: "",
        iStatus: 1,
        IsLogout: false,
      };
      state.Permission = [];
    },
    addotp: (state, { payload }) => {
      state.IsOTP = true;
      state.OTP = payload;
    },
    cancelverification: (state) => {
      state.IsOTP = false;
      state.OtpType = "New";
      state.OTP = 0;
    },
    showOTPVerification: (state, { payload }) => {
      state.OtpType = payload;
      state.IsOTP = true;
    },
    updateUserPermission: (state, { payload }) => {
      state.Permission = payload;
    },
    viewNews: (state, { payload }) => {
      state.news.iNewsId = payload.iNewsId;
      state.news.strNews = payload.strNews;
      state.news.Status = payload.status;
    },
    clearNews: (state) => {
      state.news = {
        iNewsId: 0,
        strNews: "",
        Status: 0,
      };
    },
    newsInputOnchange: (state, { payload }) => {
      if (payload.target !== undefined)
        state.news[payload.target.name] = payload.target.value;
      else state.news[payload.name] = payload.value;
    },
    viewEnquiry: (state, { payload }) => {
      state.enquiry.iEnquiryId = payload.enquiryId;
      state.enquiry.strClientName = payload.companyName;
      state.enquiry.strAdd1 = payload.add1;
      state.enquiry.strAdd2 = payload.add2;
      state.enquiry.strAdd3 = payload.add3;
      state.enquiry.strAdd4 = payload.add4;
      state.enquiry.strPinCode = payload.pincode;
      state.enquiry.strMobile = payload.mobile;
      state.enquiry.strEmail = payload.email;
      state.enquiry.strGST = payload.gst;
      state.enquiry.iStatus = payload.status;
      state.enquiry.ActiveDate = payload.activeDate;
      state.enquiry.EnquiryDate = payload.enquiryDate;
      state.enquiry.strRefferel = payload.referral;
      if (payload.enquiryId > 0 && payload.status !== 3)
        state.enquiry.IsNewClient = true;
      state.IsUpdate = true;
    },
    clearEnquiry: (state) => {
      state.enquiry = {
        iEnquiryId: 0,
        strClientName: "",
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        strUserName: "",
        strPassword: "",
        strConfPassword: "",
        strRefferel: "",
        iStatus: 1,
        ActiveDate: new Date().toISOString().split("T")[0],
        EnquiryDate: new Date().toISOString().split("T")[0],
        IsNewClient: false,
      };
    },
    enquiryInputOnchange: (state, { payload }) => {
      if (payload.target !== undefined)
        state.enquiry[payload.target.name] = payload.target.value;
      else state.enquiry[payload.name] = payload.value;
    },
    shownewenquiry: (state) => {
      state.IsUpdate = true;
      state.enquiry = {
        iEnquiryId: 0,
        strClientName: "",
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        strUserName: "",
        strPassword: "",
        strConfPassword: "",
        strRefferel: "",
        iStatus: 3,
        ActiveDate: new Date().toISOString().split("T")[0],
        EnquiryDate: new Date().toISOString().split("T")[0],
        IsNewClient: true,
      };
    },
    showscriptmaster: (state) => {
      state.IsUpdate = true;
      state.script.IsServer = true;
    },
    viewScriptClient: (state, { payload }) => {
      state.script.iClientId = payload.clientId;
      state.script.strClientName = payload.clientName;
      state.script.strCompanyName = "";
      state.script.strYear = "";
      state.script.strCompanyDB = "";
      state.script.strMasterDB = payload.strMasterDB;
      state.IsUpdate = true;
    },
    load_clientcompany: (state, { payload }) => {
      state.Data = payload.Data;
      state.Columns = payload.Columns;
    },
    viewCompany: (state, { payload }) => {
      state.script.strCompanyName = payload.strCompanyName;
      state.script.strYear = payload.strYear;
      state.script.strCompanyDB = payload.strCompanyDB;
    },
    clearScript: (state) => {
      state.script = {
        iClientId: 0,
        strClientName: "",
        strCompanyName: "",
        strYear: "",
        strCompanyDB: "",
        strMasterDB: "",
        IsMaster: false,
        Company: [],
        scripttype: "Company",
        Script: [{ Type: "Master" }, { Type: "Company" }],
        FileName: "",
        File: [],
        IsServer: false,
      };
      state.IsUpdate = false;
      state.Data = [];
      state.Columns = [];
    },
    selectfile: (state, { payload }) => {
      state.script.File = payload.File;
      state.script.FileName = payload.FileName;
    },
    clearselectfile: (state) => {
      state.script.File = [];
      state.script.FileName = "";
      state.script.scripttype = "Company";
    },
    selectscripttype: (state, { payload }) => {
      state.script.scripttype = payload;
    },
    selectdbtype: (state, { payload }) => {
      state.Restore.dbType = payload;
    },
    selectrestorefile: (state, { payload }) => {
      state.Restore.File = payload.File;
      state.Restore.FileName = payload.FileName;
    },
    clearrestoreselectfile: (state) => {
      state.Restore.File = [];
      state.Restore.FileName = "";
      state.Restore.dbType = "Company";
    },
    viewrestoreClient: (state, { payload }) => {
      state.Restore.iClientId = payload.clientId;
      state.Restore.strClientName = payload.clientName;
      state.Restore.strCompanyName = "";
      state.Restore.strYear = "";
      state.Restore.strCompanyDB = "";
      state.Restore.strMasterDB = payload.strMasterDB;
      state.IsUpdate = true;
    },
    viewrestoreCompany: (state, { payload }) => {
      state.Restore.strCompanyName = payload.strCompanyName;
      state.Restore.strYear = payload.strYear;
      state.Restore.strCompanyDB = payload.strCompanyDB;
    },
    clearrestore: (state) => {
      state.Restore = {
        iClientId: 0,
        strClientName: "",
        strCompanyName: "",
        strYear: "",
        strCompanyDB: "",
        strMasterDB: "",
        dbType: "Company",
        ScrType: [{ Type: "Master" }, { Type: "Company" }],
        FileName: "",
        File: [],
        Company: [],
      };
      state.IsUpdate = false;
    },
    viewuserclient: (state, { payload }) => {
      state.clientuser.iClientId = payload.clientId;
      state.clientuser.strMasterDB = payload.strMasterDB;
    },
    loaduserMaster: (state, { payload }) => {
      state.Data = payload.User;
      state.Columns = payload.Columns;
      state.clientuser.UserRole = payload.UserRole;
      state.clientuser.Screen = payload.ScrType;
      state.clientuser.State = payload.State;
      state.clientuser.Country = payload.Country;
      state.clientuser.Status = payload.Status;
      state.clientuser.Gender = payload.Gender;
      state.IsUpdate = true;
    },
    viewUser: (state, { payload }) => {
      const { User, Permission } = payload;
      state.clientuser.iUserId = User.iUserId;
      state.clientuser.iRoleId = User.iUserRole;
      state.clientuser.iGender = User.iGender;
      state.clientuser.strName = User.strName;
      state.clientuser.dpDOB = User.dpDOB.split("T")[0];
      state.clientuser.dpDOJ = User.dpDOJ.split("T")[0];
      state.clientuser.strUserName = User.strUserName;
      state.clientuser.strPassword = User.strPassword;
      state.clientuser.strConfirmPassword = "";
      state.clientuser.strAdd1 = User.strAdd1;
      state.clientuser.strAdd2 = User.strAdd2;
      state.clientuser.strAdd3 = User.strAdd3;
      state.clientuser.strAdd4 = User.strAdd4;
      state.clientuser.strPincode = User.strPincode;
      state.clientuser.iCountry = User.iCountry;
      state.clientuser.iState = User.istate;
      state.clientuser.strPhone = User.strPhone1;
      state.clientuser.strMobile = User.strMobile1;
      state.clientuser.strEmail = User.strEmail;
      state.clientuser.iStatus = User.iStatus;
      state.clientuser.strRemarks = User.strRemarks;
      state.clientuser.Permission = Permission;
      state.clientuser.IsLogout = User.isLogOut;
      state.clientuser.IsUser = true;
    },
    updateUserPerm: (state, { payload }) => {
      state.clientuser.Permission = payload.Permission;
      state.clientuser.iScrCatId = payload.iScrCatId;
    },
    updateUserState: (state, { payload }) => {
      state.clientuser.State = payload.State;
      state.clientuser.iCountry = payload.iCountry;
    },
    loadclientuser: (state, { payload }) => {
      state.clientuser.iUserId = payload.iUserId;
    },
    permissionallOnchange: (state, { payload }) => {
      state.clientuser.IsChecked[payload.name] = payload.val;
    },
    showcolumn: (state, { payload }) => {
      state.clientuser.IsColumns = payload;
    },
    showhideuserrole: (state, { payload }) => {
      state.clientuser.IsRole = !payload;
    },
    clientuserInputOnchange: (state, { payload }) => {
      if (payload.target !== undefined)
        state.clientuser[payload.target.name] = payload.target.value;
      else state.clientuser[payload.name] = payload.value;
    },
    clearclientuserinput: (state) => {
      state.IsUpdate = false;
      state.clientuser = {
        iClientId: 0,
        strMasterDB: "",
        IsUser: false,
        iUserId: 0,
        IsLogout: false,
        strName: "",
        dpDOB: new Date().toISOString().split("T")[0],
        dpDOJ: new Date().toISOString().split("T")[0],
        iGender: 1,
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        iRoleId: 0,
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPincode: "",
        iCountry: 1,
        iState: 32,
        strPhone: "",
        strMobile: "",
        strEmail: "",
        iStatus: 1,
        strRemarks: "",
        iScrCatId: 1,
        IsColumns: {
          IsMaster: false,
          IsTrans: false,
          IsCompany: false,
          IsReport: false,
          IsCompanyList: false,
        },
        IsChecked: {
          isNew: false,
          isView: false,
          isModify: false,
          isExport: false,
          isDelete: false,
          isCancel: false,
          isPrint: false,
          isNoAccess: false,
          bIsVisible: false,
          bShowReport: false,
          bShowCompany: false,
          isShow: false,
        },
        User: [],
        UserRole: [],
        Screen: [],
        State: [],
        Country: [],
        Status: [],
        Gender: [],
        Permission: [],
        IsRole: false,
      };
    },
    LoadDefaultstates: (state) => {
      state.iScrId = 0;
      state.ScrName = "";
      state.IsUpdate = false;
      state.IsOTP = false;
      state.OtpType = "New";
      state.OTP = "";
      state.client = {
        iClientId: "",
        iMsDBId: 0,
        iCompDBId: 0,
        strClientName: "",
        strCompanyName: 0,
        strMasterDB: "",
        strCompanyDB: 0,
        logedinDevice: 0,
        MaxDevice: 0,
        iYearId: 0,
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        ActivationDate: new Date().toISOString().split("T")[0],
        ExpiryDate: new Date().toISOString().split("T")[0],
        Subscription: "",
      };
      state.user = {
        iUserId: 0,
        iRole: 0,
        strName: "",
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        strDoorNo: "",
        strStreet: "",
        strAreaName: "",
        strPincode: "",
        strCity: "",
        strMobile: "",
        iStatus: 1,
        IsLogout: false,
      };
      state.news = {
        iNewsId: 0,
        strNews: "",
        Status: 0,
      };
      state.enquiry = {
        iEnquiryId: 0,
        strClientName: "",
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPinCode: "",
        strMobile: "",
        strEmail: "",
        strGST: "",
        strUserName: "",
        strPassword: "",
        strConfPassword: "",
        strRefferel: "",
        iStatus: 1,
        ActiveDate: new Date().toISOString().split("T")[0],
        EnquiryDate: new Date().toISOString().split("T")[0],
        IsNewClient: false,
      };
      state.permission = {
        IsCreate: false,
        IsModify: false,
        IsDelete: false,
        IsView: false,
        IsBackup: false,
        IsRestore: false,
        IsNoAccess: false,
      };
      state.script = {
        iClientId: 0,
        strClientName: "",
        strCompanyName: "",
        strYear: "",
        strCompanyDB: "",
        strMasterDB: "",
        IsMaster: false,
        Company: [],
        scripttype: "Company",
        Script: [{ Type: "Master" }, { Type: "Company" }],
        FileName: "",
        File: [],
        IsServer: false,
      };
      state.Restore = {
        iClientId: 0,
        strClientName: "",
        strCompanyName: "",
        strYear: "",
        strCompanyDB: "",
        strMasterDB: "",
        dbType: "Company",
        ScrType: [{ Type: "Master" }, { Type: "Company" }],
        FileName: "",
        File: [],
        Company: [],
      };
      state.clientuser = {
        iClientId: 0,
        strMasterDB: "",
        IsUser: false,
        iUserId: 0,
        IsLogout: false,
        strName: "",
        dpDOB: new Date().toISOString().split("T")[0],
        dpDOJ: new Date().toISOString().split("T")[0],
        iGender: 1,
        strUserName: "",
        strPassword: "",
        strConfirmPassword: "",
        iRoleId: 0,
        strAdd1: "",
        strAdd2: "",
        strAdd3: "",
        strAdd4: "",
        strPincode: "",
        iCountry: 1,
        iState: 32,
        strPhone: "",
        strMobile: "",
        strEmail: "",
        iStatus: 1,
        strRemarks: "",
        iScrCatId: 1,
        IsColumns: {
          IsMaster: false,
          IsTrans: false,
          IsCompany: false,
          IsReport: false,
          IsCompanyList: false,
        },
        IsChecked: {
          isNew: false,
          isView: false,
          isModify: false,
          isExport: false,
          isDelete: false,
          isCancel: false,
          isPrint: false,
          isNoAccess: false,
          bIsVisible: false,
          bShowReport: false,
          bShowCompany: false,
          isShow: false,
        },
        User: [],
        UserRole: [],
        Screen: [],
        State: [],
        Country: [],
        Status: [],
        Gender: [],
        Permission: [],
        IsRole: false,
      };
      state.Data = [];
      state.Columns = [];
      state.Status = [];
      state.Role = [];
      state.Permission = [];
    },
  },
});
export const {
  initialCommonparems,
  initalscreenname,
  clientInputOnchange,
  reassignClientdata,
  clearClientinput,
  clearDefaultstates,
  userInputOnchange,
  reassignuserdata,
  clearUserinput,
  addotp,
  cancelverification,
  showOTPVerification,
  updateUserPermission,
  createNewuser,
  viewNews,
  clearNews,
  newsInputOnchange,
  viewEnquiry,
  clearEnquiry,
  enquiryInputOnchange,
  shownewenquiry,
  showscriptmaster,
  viewScriptClient,
  load_clientcompany,
  viewCompany,
  clearScript,
  selectfile,
  selectscripttype,
  selectdbtype,
  selectrestorefile,
  clearrestore,
  viewrestoreClient,
  viewrestoreCompany,
  loaduserMaster,
  viewuserclient,
  viewUser,
  loadclientuser,
  updateUserPerm,
  updateUserState,
  permissionallOnchange,
  showcolumn,
  clientuserInputOnchange,
  showhideuserrole,
  clearclientuserinput,
  clearselectfile,
  clearrestoreselectfile,
  LoadDefaultstates,
} = CommonSlice.actions;

export const ValidateUser_Permission = async (scrId) => {
  const permission = {
    IsCreate: false,
    IsModify: false,
    IsDelete: false,
    IsView: false,
    IsBackup: false,
    IsRestore: false,
    IsNoAccess: false,
    ScrName: "",
  };
  let authparem = await DecryptData(localStorage.jbctlparm);
  let Data = JSON.parse(authparem.mnper);
  if (Data.length > 0) {
    Data = Data.filter((dt) => dt.scrId === scrId)[0];
    permission.IsCreate = Data.isCreate;
    permission.IsModify = Data.isModify;
    permission.IsDelete = Data.isDelete;
    permission.IsView = Data.isView;
    permission.IsBackup = Data.isBackup;
    permission.IsRestore = Data.isRestore;
    permission.IsNoAccess = Data.isNoAccess;
    permission.ScrName = Data.scrName;
  }
  return permission;
};
export default CommonSlice.reducer;

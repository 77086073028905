import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import { initalscreenname, LoadDefaultstates } from "./features/Common";

function AvailableDatabase() {
  const dispatch = useDispatch();
  const [Master, setMaster] = useState([]);
  const [Company, setCompany] = useState([]);
  useEffect(() => {
    Load_Availabledatabase();
  }, []);

  const Load_Availabledatabase = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp
        .get("DBMaster/Load_Availabledatabase")
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            setMaster(res.data.Master);
            setCompany(res.data.Company);
            await dispatch(initalscreenname("Available Database"));
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <div className="Dash-content">
      <div className="Dash-card1">
        <div className="Dash-wrapper">
          <div className="row">
            <div className="table-admin p-2">
              <table className="option-list">
                <thead>
                  <tr>
                    <th>Master Database</th>
                  </tr>
                </thead>
                <tbody>
                  {Master.map((db, trIndex) => (
                    <tr key={trIndex} tabIndex={trIndex}>
                      <td>{db.strDBName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-admin p-2">
              <table className="option-list">
                <thead>
                  <tr>
                    <th>Company Database</th>
                  </tr>
                </thead>
                <tbody>
                  {Company.map((db, trIndex) => (
                    <tr key={trIndex} tabIndex={trIndex}>
                      <td>{db.strDBName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailableDatabase;

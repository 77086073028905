import { createSlice } from "@reduxjs/toolkit";
export const MessageSlice = createSlice({
  name: "Parems",
  initialState: {
    Msg_Parem: {
      Message: "",
      Type: "",
      isMsg: false,
      Button: "",
      Question: "",
      isdialog: false,
      isloading: true,
      parem1: "",
      parem2: "",
      parem3: "",
      parem4: "",
    },
  },
  reducers: {
    Show_MsgBox: (state, { payload }) => {
      if (payload.isMsg) {
        state.Msg_Parem.Message = payload.Message;
        state.Msg_Parem.Type = payload.Type;
        state.Msg_Parem.isMsg = payload.isMsg;
      } else {
        state.Msg_Parem.Button = payload.Button;
        state.Msg_Parem.Question = payload.Question;
        state.Msg_Parem.isdialog = payload.isdialog;
        state.Msg_Parem.parem1 = payload.parem1 ? payload.parem1 : "";
        state.Msg_Parem.parem2 = payload.parem2 ? payload.parem2 : "";
        state.Msg_Parem.parem3 = payload.parem3 ? payload.parem3 : "";
        state.Msg_Parem.parem4 = payload.parem4 ? payload.parem4 : "";
      }
    },
    Hide_DialogMsgBox: (state) => {
      state.Msg_Parem = {
        isMsg: false,
        Button: "",
        Question: "",
        isdialog: false,
        parem1: "",
        parem2: "",
        parem3: "",
        parem4: "",
      };
    },
    Hide_AlertMsgBox: (state) => {
      state.Msg_Parem = {
        Message: "",
        Type: "",
        isMsg: false,
      };
    },
    setload_Screen: (state, action) => {
      state.Msg_Parem.isloading = action.payload;
    },
  },
});
export const {
  Show_MsgBox,
  Hide_DialogMsgBox,
  Hide_AlertMsgBox,
  setload_Screen,
} = MessageSlice.actions;
export default MessageSlice.reducer;

import { showOTPVerification } from "./features/Common";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";

const SendOTP = async (iScrId, type, Name, dispatch) => {
  try {
    await dispatch(setload_Screen(true));
    const parems = {
      Otp: "",
      strUserName: Name,
      strotptype: type,
      iScrId: iScrId,
    };
    await adminApp
      .post(`ClientMaster/SendEmailAsync`, parems)
      .then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(showOTPVerification(type));
        } else
          await dispatch(
            Show_MsgBox({
              Message: res.data.strMessage,
              Type: "error",
              isMsg: true,
            })
          );
      });
  } catch (err) {
    await dispatch(
      Show_MsgBox({
        Message: err.message,
        Type: "error",
        isMsg: true,
      })
    );
  } finally {
    await dispatch(setload_Screen(false));
  }
};
export default SendOTP;

import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";
import { setload_Screen, Show_MsgBox } from "./features/Message";
const adminApp = axios;

const DevEnv = `localhost:5003`;
const ProEnv = `adminserver.justbillsoftware.com`;
const TestEnv = `admintestserver.justbillsoftware.com`;
export const API_URL = `https://${TestEnv}/api/`;

export const EncryptData = async (data) => {
  const encryptedData = await CryptoJS.AES.encrypt(
    JSON.stringify(data),
    localStorage.jbadmparetkau
  ).toString();
  return encryptedData;
};
export const DecryptData = async (data) => {
  if (data) {
    const bytes = await CryptoJS.AES.decrypt(data, localStorage.jbadmparetkau);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else return "";
};
adminApp.interceptors.request.use(
  async function (config) {
    config.baseURL = API_URL;
    config.headers["Authorization"] = `Bearer ${localStorage.jbadmparetkau}`;
    return config;
  },
  function (e) {
    return Promise.reject(e);
  }
);
adminApp.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (e) {
    await authorizUser(e);
    return Promise.reject(e);
  }
);
const authorizUser = async (e) => {
  if (e.response && e.response.status === 401) {
    await Logout_User();
  }
};
export const Logout_User = async () => {
  if (localStorage.jbctlparm) {
    let authparem = await DecryptData(localStorage.jbctlparm);
    await adminApp.post(`UserMaster/Logout_Admin/${Number(authparem.User)}`);
  }
  await localStorage.clear();
  await sessionStorage.clear();
  window.location.href = "/";
};
export const calculate_keyexpiry = (ExpDate) => {
  const date1 = moment(new Date());
  const date2 = moment(ExpDate);
  const days = date2.diff(date1, "days");
  return days + " Days";
};
export const Backup_Data = async ({ Name, dispatch, Msdb }) => {
  let Status = false;
  try {
    await dispatch(setload_Screen(true));
    await fetch(`${API_URL}DBMaster/Backup_Database/${Msdb}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.jbadmparetkau}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = url;
        const now = moment();
        const curr_date = now.format("D-MMM-YYYY");
        const ClientName = Name ? Name : "JustBill";
        const FileName = `${ClientName}_${curr_date}.zip`;
        link.setAttribute("download", FileName);
        link.click();
        Status = true;
      })
      .catch(async (err) => {
        await dispatch(
          Show_MsgBox({ Message: err.stack, Type: "error", isMsg: true })
        );
        Status = false;
      });
  } catch (err) {
    await dispatch(
      Show_MsgBox({ Message: err.message, Type: "error", isMsg: true })
    );
    Status = false;
  } finally {
    await dispatch(setload_Screen(false));
    return Status;
  }
};
export default adminApp;

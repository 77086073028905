import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import Admintable from "./Admintable";
import CustomizedSelectoption from "./CustomizedSelectoption";
import {
  clearScript,
  clearselectfile,
  initialCommonparems,
  load_clientcompany,
  LoadDefaultstates,
  selectfile,
  selectscripttype,
  showscriptmaster,
  ValidateUser_Permission,
} from "./features/Common";
function ScriptMaster() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const _script = useSelector((store) => store.Common.script);
  const _select = useSelector((state) => state.SelectOption.parems);

  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_Clients").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 8,
              Data: res.data.Clients,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(8),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  useEffect(() => {
    const View_Client = async () => {
      try {
        if (_script.iClientId > 0) {
          await dispatch(setload_Screen(true));
          await adminApp
            .get(`CompanyMaster/Load_ClientCompany/${_script.iClientId}`)
            .then((res) => {
              if (res.data.strStatus === "Success") {
                dispatch(
                  load_clientcompany({
                    Data: res.data.Company,
                    Columns: res.data.Columns,
                  })
                );
              } else {
                AlertMessage(res.data.strMessage, "error");
              }
            });
        }
      } catch (err) {
        AlertMessage(err.message, "error");
      } finally {
        await dispatch(setload_Screen(false));
      }
    };
    View_Client();
  }, [_script.iClientId]);

  const Execute_Server = async () => {
    try {
      await dispatch(setload_Screen(true));
      const formdata = new FormData();
      formdata.append("File", _script.File);
      formdata.append("FileName", _script.FileName);
      formdata.append("ScriptType", _script.scripttype);
      await adminApp
        .post("DBMaster/Execute_ServerScript", formdata)
        .then((resu) => {
          if (resu.data.strStatus === "Success") {
            AlertMessage(resu.data.strMessage, "success");
          } else {
            AlertMessage(resu.data.strMessage, "error");
          }
        });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Execute_Script = async () => {
    try {
      await dispatch(setload_Screen(true));
      let Database = "";
      if (_script.scripttype === "Master") Database = _script.strMasterDB;
      else Database = _script.strCompanyDB;
      const formdata = new FormData();
      formdata.append("File", _script.File);
      formdata.append("FileName", _script.FileName);
      formdata.append("ScriptType", _script.scripttype);
      formdata.append("Database", Database);
      await adminApp.post("DBMaster/Execute_Script", formdata).then((resu) => {
        if (resu.data.strStatus === "Success") {
          AlertMessage(resu.data.strMessage, "success");
        } else {
          AlertMessage(resu.data.strMessage, "error");
        }
      });
    } catch (e) {
      AlertMessage(e.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Clear_Click = async () => {
    await dispatch(clearScript());
    await Load_Clients();
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  return (
    <>
      {!_common.IsUpdate && (
        <>
          <Admintable IsBackground={true} />
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              onClick={(e) => dispatch(showscriptmaster())}
            >
              Server Script&nbsp;<i className="bx bx-code-alt"></i>
            </button>
          </div>
        </>
      )}
      {_common.IsUpdate && (
        <>
          <div className="card-wrapper">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <label>Script Type</label>
                <CustomizedSelectoption
                  optionparem="isoptionShow"
                  showoption={_select.isoptionShow}
                  placeholder="Select Server Script type"
                  select_value={(val) => dispatch(selectscripttype(val))}
                  btnname=""
                  btnshow={false}
                  show_popup={""}
                  valueonly={true}
                  defaultval={_script.scripttype}
                  displayName="Type"
                  disvalue="Type"
                  arrydata={_script.Script}
                  disabled={false}
                  EmptVal="Server Script type"
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <label>Select Script File</label>
                <div className="custom-file-upload">
                  <label htmlFor="file-upload" className="custom-file-label">
                    {_script.FileName.length > 0
                      ? _script.FileName
                      : "Choose Script File"}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => {
                      dispatch(
                        selectfile({
                          File: e.target.files[0],
                          FileName: e.target.files[0].name.split(".")[0],
                        })
                      );
                    }}
                    accept=".sql"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 mt-4">
                <button
                  className="btn-fabgreen"
                  onClick={(e) => {
                    var file = document.getElementById("file-upload");
                    file.value = "";
                    dispatch(clearselectfile());
                  }}
                >
                  Clear&nbsp;<i className="fa-solid fa-broom"></i>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <label>Client Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Client Name"
                  defaultValue={_script.strClientName}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Master Database</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Master Database"
                  defaultValue={_script.strMasterDB}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Comapany Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  defaultValue={_script.strCompanyName}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Financial Year</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Financial Year"
                  defaultValue={_script.strYear}
                  disabled
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <label>Company Database</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Database"
                  defaultValue={_script.strCompanyDB}
                  disabled
                />
              </div>
            </div>
            {!_script.IsServer && (
              <div className="mt-2">
                <Admintable IsBackground={true} />
              </div>
            )}
          </div>
          <div className="btn-section">
            <button
              className="btn-fabgreen"
              disabled={_script.IsServer ? !_common.permission.IsModify : true}
              onClick={(e) => {
                if (_script.FileName.length === 0)
                  AlertMessage("Please Select Script File", "info");
                else if (_script.scripttype !== _script.FileName)
                  AlertMessage("Script Type File Name Doesn't Match", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: Execute_Server,
                      Question: `Do You Want to Update ${_script.scripttype} Script?`,
                      isdialog: true,
                    })
                  );
              }}
            >
              Execute Server&nbsp;<i className="bx bx-code-alt"></i>
            </button>
            <button
              disabled={!_script.IsServer ? !_common.permission.IsModify : true}
              className="btn-fabgreen"
              onClick={(e) => {
                if (_script.FileName.length === 0)
                  AlertMessage("Please Select Script File", "info");
                else if (
                  _script.scripttype === "Company" &&
                  _script.strCompanyDB.length === 0
                )
                  AlertMessage("Please Select Company", "info");
                else if (_script.scripttype !== _script.FileName)
                  AlertMessage("Script Type File Name Doesn't Match", "info");
                else
                  dispatch(
                    Show_MsgBox({
                      Button: Execute_Script,
                      Question: `Do You Want to Update ${_script.scripttype} Script?`,
                      isdialog: true,
                    })
                  );
              }}
            >
              Execute Master&nbsp;<i className="bx bx-code-alt"></i>
            </button>
            <button className="btn-fabgreen" onClick={() => Clear_Click()}>
              Clear&nbsp;<i className="fa-solid fa-broom"></i>
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default ScriptMaster;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Admintable from "./Admintable";
import {
  clearDefaultstates,
  clearUserinput,
  createNewuser,
  initialCommonparems,
  LoadDefaultstates,
  updateUserPermission,
  userInputOnchange,
  ValidateUser_Permission,
} from "./features/Common";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp from "./Utility";
import CustomizedSelectoption from "./CustomizedSelectoption";
import $ from "jquery";
import OTPValidation from "./OTPValidation";
import SendOTP from "./generateOTP";

function AdminMaster() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const _user = useSelector((store) => store.Common.user);
  const _select = useSelector((state) => state.SelectOption.parems);

  useEffect(() => {
    Load_AdminUser();
  }, []);

  const Load_AdminUser = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("UserMaster/Load_AdminUser/").then(async (res) => {
        if (res.data.strStatus === "Success") {
          await dispatch(
            initialCommonparems({
              iScrId: 2,
              Data: res.data.User,
              Columns: res.data.Columns,
              Status: res.data.Status,
              Role: res.data.Role,
              permission: await ValidateUser_Permission(2),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Save_AdminUser = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        User: {
          iUserId: _user.iUserId,
          iRole: _user.iRole,
          strRole: "",
          strName: _user.strName,
          strUserName: _user.strUserName,
          strPassword: _user.strPassword,
          strDoorNo: _user.strDoorNo,
          strStreet: _user.strStreet,
          strAreaName: _user.strAreaName,
          strPincode: _user.strPincode,
          strCity: _user.strCity,
          strMobile: _user.strMobile,
          iStatus: _user.iStatus,
          iLogOut: Number(_user.IsLogout),
          Token: "",
        },
        OTP: {
          iUserId: Number(_user.iUserId),
          Otp: _common.OTP,
          strUserName: _user.strUserName,
          strotptype: _common.OtpType,
        },
        Permission: _common.Permission,
      };
      await adminApp
        .post(`UserMaster/InsUpd_AdminUser`, parems)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            await Close_AdminUser();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      await AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_AdminUser = async () => {
    try {
      await dispatch(setload_Screen(true));
      const parems = {
        iUserId: Number(_user.iUserId),
        Otp: _common.OTP,
        strUserName: _user.strUserName,
        strotptype: _common.OtpType,
      };
      await adminApp
        .post(`UserMaster/Delete_AdminUser`, parems)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            await Close_AdminUser();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Clear_AdminUser = async () => {
    await dispatch(clearUserinput());
  };
  const Close_AdminUser = async () => {
    await dispatch(clearDefaultstates());
    await Load_AdminUser();
  };
  const inputOnchange = async (e) => {
    await dispatch(userInputOnchange(e));
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  const Verifyclick = () => {
    if (_common.OtpType === "New") Save_AdminUser();
    else if (_common.OtpType === "Update") Save_AdminUser();
    else if (_common.OtpType === "Delete") Delete_AdminUser();
  };
  const tablestyle = {
    textAlign: "center",
    width: "100px",
    maxWidth: "100px",
  };
  const permissionOnchange = async (perm, val, col) => {
    let permission = JSON.parse(JSON.stringify(_common.Permission));
    permission = permission.map((dt) => {
      if (dt.permId === perm.permId) {
        dt[col] = val;
      }
      return dt;
    });
    await dispatch(updateUserPermission(permission));
  };
  return !_common.IsUpdate ? (
    <>
      <Admintable IsBackground={true} />
      <div className="btn-section">
        <button
          className="btn-fabgreen"
          onClick={async (e) => {
            await dispatch(createNewuser());
          }}
          disabled={!_common.permission.IsCreate}
        >
          New User&nbsp;<i className="bx bxs-user-plus"></i>
        </button>
      </div>
    </>
  ) : (
    <>
      <div className="card-wrapper">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="strName"
              value={_user.strName}
              onChange={(e) => inputOnchange(e)}
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>User Role</label>
            <CustomizedSelectoption
              optionparem="isoptionShow"
              showoption={_select.isoptionShow}
              placeholder="Select User Role"
              select_value={(val) =>
                inputOnchange({ name: "iRole", value: val })
              }
              btnname="User Role"
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={_user.iRole}
              displayName="strRoleName"
              disvalue="iRoleId"
              arrydata={_common.Role}
              disabled={false}
              EmptVal="User Role "
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>E-Mail</label>
            <input
              type="email"
              className="form-control"
              name="strUserName"
              value={_user.strUserName}
              onChange={(e) => inputOnchange(e)}
              placeholder="E-Mail"
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Password</label>
            <input
              type="Password"
              className="form-control"
              name="strPassword"
              value={_user.strPassword}
              onChange={(e) => inputOnchange(e)}
              placeholder="Enter Password"
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Confirm Password</label>
            <input
              type="text"
              className="form-control"
              name="strConfirmPassword"
              value={_user.strConfirmPassword}
              onChange={(e) => inputOnchange(e)}
              placeholder="Enter Confirm Password"
              spellCheck={false}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Door/Office No</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              placeholder="Enter Door/Office No"
              spellCheck={false}
              tabIndex={5}
              name="strDoorNo"
              value={_user.strDoorNo}
              onChange={(e) => inputOnchange(e)}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Street / Area</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              placeholder="Enter Street / Area"
              spellCheck={false}
              tabIndex={5}
              name="strStreet"
              value={_user.strStreet}
              onChange={(e) => inputOnchange(e)}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Village / Town</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              placeholder="Enter Village / Town"
              spellCheck={false}
              tabIndex={5}
              name="strAreaName"
              autoComplete="off"
              value={_user.strAreaName}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>City</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              tabIndex={4}
              value={_user.strCity}
              spellCheck={false}
              autoComplete="off"
              name="strCity"
              placeholder="Enter City Name"
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Pincode</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              tabIndex={4}
              placeholder="Enter Pincode"
              name="strPincode"
              spellCheck={false}
              autoComplete="off"
              value={_user.strPincode}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Mobile</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              type="tel"
              onClick={(e) => e.target.select()}
              spellCheck={false}
              className="form-control"
              tabIndex={6}
              placeholder="Enter Mobile No"
              name="strMobile"
              autoComplete="off"
              value={_user.strMobile}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Status</label>
            <CustomizedSelectoption
              optionparem="isoptionShow1"
              showoption={_select.isoptionShow1}
              placeholder="Select Status"
              select_value={(val) =>
                inputOnchange({ name: "iStatus", value: val })
              }
              btnname="State"
              btnshow={false}
              show_popup={""}
              valueonly={true}
              defaultval={_user.iStatus}
              displayName="status"
              disvalue="statusId"
              arrydata={_common.Status}
              disabled={false}
              EmptVal="Status "
            />
          </div>
          <div className="col-sm-12 col-md-3 mt-1">
            <label>Logout User</label>
            <input
              type="checkbox"
              className="form-check"
              placeholder="Enter Remark"
              name="IsLogout"
              tabIndex={8}
              checked={_user.IsLogout}
              onChange={(e) =>
                inputOnchange({
                  name: "IsLogout",
                  value: e.target.checked,
                })
              }
              autoComplete="off"
              spellCheck={false}
            />
          </div>
          <div className="table-admin mt-2">
            <table className="option-list">
              <thead>
                <tr>
                  <th>Screen Name</th>
                  <th>Create</th>
                  <th>Modify</th>
                  <th>View</th>
                  <th>Delete</th>
                  <th>Backup</th>
                  <th>Restore</th>
                  <th>No Access</th>
                </tr>
              </thead>
              <tbody>
                {_common.Permission.map((Perm, Index) => (
                  <tr key={Index}>
                    <td>{Perm.scrName}</td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isCreate}
                        onChange={(e) =>
                          permissionOnchange(Perm, !Perm.isCreate, "isCreate")
                        }
                      />
                    </td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isView}
                        onChange={(e) =>
                          permissionOnchange(Perm, !Perm.isView, "isView")
                        }
                      />
                    </td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isModify}
                        onChange={(e) =>
                          permissionOnchange(Perm, !Perm.isModify, "isModify")
                        }
                      />
                    </td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isDelete}
                        onChange={(e) =>
                          permissionOnchange(Perm, !Perm.isDelete, "isDelete")
                        }
                      />
                    </td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isBackup}
                        onChange={(e) =>
                          permissionOnchange(Perm, !Perm.isBackup, "isBackup")
                        }
                      />
                    </td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isRestore}
                        onChange={(e) =>
                          permissionOnchange(Perm, !Perm.isRestore, "isRestore")
                        }
                      />
                    </td>
                    <td style={tablestyle}>
                      <input
                        type="checkbox"
                        defaultChecked={Perm.isNoAccess}
                        onChange={(e) =>
                          permissionOnchange(
                            Perm,
                            !Perm.isNoAccess,
                            "isNoAccess"
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {_common.IsOTP && <OTPValidation Verifyclick={Verifyclick} />}
      </div>
      <div className="btn-section">
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            if (_user.strUserName.length === 0)
              AlertMessage("Please Enter Valid User Info", "info");
            if (_user.strPassword !== _user.strConfirmPassword)
              AlertMessage("Password Doesn't Match", "info");
            else {
              dispatch(
                Show_MsgBox({
                  Button: SendOTP,
                  Question: "Do You Want to Save Admin User Info?",
                  isdialog: true,
                  parem1: 2,
                  parem2: _user.iUserId === 0 ? "New" : "Update",
                  parem3: _user.strUserName,
                  parem4: dispatch,
                })
              );
            }
          }}
          disabled={!_common.permission.IsCreate}
        >
          Save&nbsp;<i className="bx bxs-save"></i>
        </button>
        <button
          className="btn-fabgreen"
          onClick={() => {
            if (_user.iUserId === 0)
              AlertMessage("Please Select Admin User", "info");
            else {
              dispatch(
                Show_MsgBox({
                  Button: SendOTP,
                  Question: "Do You Want to Delete Admin User?",
                  isdialog: true,
                  parem1: 2,
                  parem2: "Delete",
                  parem3: _user.strUserName,
                  parem4: dispatch,
                })
              );
            }
          }}
          disabled={!_common.permission.IsDelete}
        >
          Delete&nbsp;<i className="bx bx-trash-alt"></i>
        </button>
        <button className="btn-fabgreen" onClick={() => Clear_AdminUser()}>
          Clear&nbsp;<i className="fa-solid fa-broom"></i>
        </button>
        <button className="btn-fabgreen" onClick={(e) => Close_AdminUser()}>
          Close&nbsp;<i className="fas fa-times"></i>
        </button>
      </div>
    </>
  );
}

export default AdminMaster;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./style.css";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import SelectReducer from "./components/features/Customeselect";
import MessageReducer from "./components/features/Message";
import CommonReducer from "./components/features/Common";

const store = configureStore({
  reducer: {
    Common: CommonReducer,
    SelectOption: SelectReducer,
    Message: MessageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();

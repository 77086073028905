import { useDispatch, useSelector } from "react-redux";
import { setload_Screen, Show_MsgBox } from "./features/Message";
import adminApp, { Backup_Data, calculate_keyexpiry } from "./Utility";
import { useEffect } from "react";
import $ from "jquery";
import Admintable from "./Admintable";
import {
  clearDefaultstates,
  clientInputOnchange,
  initialCommonparems,
  LoadDefaultstates,
  ValidateUser_Permission,
} from "./features/Common";
import OTPValidation from "./OTPValidation";
import SendOTP from "./generateOTP";

function ClientMaster() {
  const dispatch = useDispatch();
  const _common = useSelector((store) => store.Common);
  const _client = useSelector((store) => store.Common.client);

  useEffect(() => {
    Load_Clients();
  }, []);
  const Load_Clients = async () => {
    try {
      await dispatch(LoadDefaultstates());
      await dispatch(setload_Screen(true));
      await adminApp.get("ClientMaster/Load_Clients").then(async (res) => {
        if (res.data.strStatus === "Success") {
          res.data.Clients.map(async (dt) => {
            dt.subscription = await calculate_keyexpiry(dt.expiryDate);
            return dt;
          });
          await dispatch(
            initialCommonparems({
              iScrId: 1,
              Data: res.data.Clients,
              Columns: res.data.Columns,
              permission: await ValidateUser_Permission(1),
            })
          );
        } else {
          AlertMessage(res.data.strMessage, "error");
        }
      });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Update_Client = async () => {
    try {
      const parems = {
        OTP: {
          iUserId: 0,
          Otp: _common.OTP,
          strUserName: _client.strClientName,
          strotptype: _common.OtpType,
        },
        client: {
          ClientId: _client.iClientId,
          ClientName: _client.strClientName,
          Add1: _client.strAdd1,
          Add2: _client.strAdd2,
          Add3: _client.strAdd3,
          Add4: _client.strAdd4,
          PinCode: _client.strPinCode,
          Mobile: _client.strMobile,
          Email: _client.strEmail,
          GST: _client.strGST,
          ActivationDate: _client.ActivationDate,
          ExpiryDate: _client.ExpiryDate,
          Subscription: _client.Subscription,
          MaxDevice: _client.MaxDevice,
          MasterDB: _client.iMsDBId,
          strMasterDB: "",
          strUserName: "",
          strPassword: "",
          iStatus: 1,
          iUserId: 0,
          iRoleId: 0,
        },
      };
      await adminApp
        .post("ClientMaster/InsUpd_ClientMaster", parems)
        .then(async (res) => {
          if (res.data.strStatus === "Success") {
            AlertMessage(res.data.strMessage, "success");
            await Close_Click();
          } else {
            AlertMessage(res.data.strMessage, "error");
          }
        });
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      await dispatch(setload_Screen(false));
    }
  };
  const Delete_Client = async () => {
    try {
      const res = await Backup_Data({
        dispatch: dispatch,
        Msdb: _client.strMasterDB,
      });
      if (res) {
        await dispatch(setload_Screen(true));
        const parems = {
          OTP: {
            iUserId: 0,
            Otp: _common.OTP,
            strUserName: _client.strClientName,
            strotptype: _common.OtpType,
          },
          iClientId: _client.iClientId,
        };
        await adminApp
          .post(`ClientMaster/Delete_ClientMaster`, parems)
          .then(async (res) => {
            if (res.data.strStatus === "Success") {
              AlertMessage(res.data.strMessage, "success");
              await Close_Click();
            } else {
              AlertMessage(res.data.strMessage, "error");
            }
          });
      }
    } catch (err) {
      AlertMessage(err.message, "error");
    } finally {
      dispatch(setload_Screen(false));
    }
  };
  const Close_Click = async () => {
    await dispatch(clearDefaultstates());
    await Load_Clients();
  };
  const find_datedifference = async () => {
    let date1 = new Date(_client.ActivationDate);
    let date2 = new Date(_client.ExpiryDate);
    var time_difference = date2.getTime() - date1.getTime();
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    await inputOnchange({
      name: "Subscription",
      value: days_difference + " Days",
    });
  };
  const TabIndex = (e) => {
    var ActiveIndex = document.activeElement.tabIndex;
    if (e.key === "ArrowUp") {
      if (ActiveIndex > 1 && ActiveIndex <= 20) {
        $("[tabindex='" + (ActiveIndex - 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex - 1) + "']").select();
      } else {
        $("[tabindex='" + 19 + "']").focus();
        $("[tabindex='" + 19 + "']").select();
      }
    }
    if (e.key === "ArrowDown") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
    if (e.key === "Enter") {
      if (ActiveIndex >= 1 && ActiveIndex < 20) {
        $("[tabindex='" + (ActiveIndex + 1) + "']").focus();
        $("[tabindex='" + (ActiveIndex + 1) + "']").select();
      } else {
        $("[tabindex='" + 1 + "']").focus();
        $("[tabindex='" + 1 + "']").select();
      }
    }
  };
  const AlertMessage = (Message, Icon) => {
    dispatch(Show_MsgBox({ Message: Message, Type: Icon, isMsg: true }));
  };
  const Backup = async () => {
    await Backup_Data({ dispatch: dispatch, Msdb: _client.strMasterDB });
  };
  const Verifyclick = () => {
    if (_common.OtpType === "New") Update_Client();
    else if (_common.OtpType === "Update") Update_Client();
    else if (_common.OtpType === "Delete") Delete_Client();
  };
  const inputOnchange = async (e) => {
    await dispatch(clientInputOnchange(e));
  };
  return !_common.IsUpdate ? (
    <Admintable IsBackground={true} />
  ) : (
    <>
      <div className="card-wrapper">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <label>Client Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Client Name"
              name="strClientName"
              value={_client.strClientName}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Master Database</label>
            <input
              type="text"
              className="form-control"
              tabIndex={1}
              name="strMasterDB"
              defaultValue={_client.strMasterDB}
              readOnly
              placeholder="Master Database"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Max Device Allowed</label>
            <input
              type="number"
              className="form-control"
              tabIndex={1}
              name="MaxDevice"
              value={_client.MaxDevice}
              onChange={(e) => inputOnchange(e)}
              autoComplete="off"
              spellCheck={false}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Activation Date</label>
            <input
              type="date"
              className="form-control"
              tabIndex={1}
              name="ActivationDate"
              value={_client.ActivationDate}
              readOnly
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Expiry Date</label>
            <input
              type="date"
              className="form-control"
              tabIndex={1}
              value={_client.ExpiryDate}
              name="ExpiryDate"
              onChange={(e) => inputOnchange(e)}
              onBlur={() => find_datedifference()}
              autoComplete="off"
              spellCheck={false}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Subscription</label>
            <input
              type="text"
              className="form-control"
              tabIndex={1}
              value={_client.Subscription}
              name="Subscription"
              readOnly
              placeholder="License Valid Upto"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Door/Office No</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              placeholder="Enter Door/Office No"
              spellCheck={false}
              tabIndex={5}
              name="strAdd1"
              value={_client.strAdd1}
              onChange={(e) => inputOnchange(e)}
              autoComplete="off"
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Street / Area</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              placeholder="Enter Street / Area"
              spellCheck={false}
              tabIndex={5}
              name="strAdd2"
              autoComplete="off"
              value={_client.strAdd2}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Village / Town</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              placeholder="Enter Village / Town"
              spellCheck={false}
              tabIndex={5}
              autoComplete="off"
              name="strAdd3"
              value={_client.strAdd3}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>City</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              tabIndex={4}
              value={_client.strAdd4}
              name="strAdd4"
              autoComplete="off"
              spellCheck={false}
              placeholder="Enter City Name"
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Pincode</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="number"
              className="form-control"
              tabIndex={4}
              placeholder="Enter Pincode"
              name="strPinCode"
              autoComplete="off"
              spellCheck={false}
              value={_client.strPinCode}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Mobile</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              type="number"
              onClick={(e) => e.target.select()}
              spellCheck={false}
              className="form-control"
              tabIndex={6}
              placeholder="Enter Mobile No"
              name="strMobile"
              autoComplete="off"
              value={_client.strMobile}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>Email</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              type="text"
              onClick={(e) => e.target.select()}
              spellCheck={false}
              className="form-control"
              placeholder="Enter Email"
              name="strEmail"
              tabIndex={6}
              autoComplete="off"
              value={_client.strEmail}
              onChange={(e) => inputOnchange(e)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label>GST No</label>
            <input
              onKeyDown={(e) => TabIndex(e)}
              onClick={(e) => e.target.select()}
              type="text"
              className="form-control"
              tabIndex={4}
              placeholder="Enter GST"
              name="strGST"
              value={_client.strGST}
              autoComplete="off"
              spellCheck={false}
              onChange={(e) => inputOnchange(e)}
              maxLength={15}
            />
          </div>
        </div>
        {_common.IsOTP && <OTPValidation Verifyclick={Verifyclick} />}
      </div>
      <div className="btn-section">
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            if (_client.iClientId === 0)
              AlertMessage("Please Select Client", "info");
            else
              dispatch(
                Show_MsgBox({
                  Button: SendOTP,
                  Question: "Do You Want to Update Client Info?",
                  isdialog: true,
                  parem1: 1,
                  parem2: "Update",
                  parem3: _client.strClientName,
                  parem4: dispatch,
                })
              );
          }}
          disabled={!_common.permission.IsCreate}
        >
          Save&nbsp;<i className="bx bxs-save"></i>
        </button>
        <button
          className="btn-fabgreen"
          onClick={() => {
            if (_client.iClientId === 0)
              AlertMessage("Please Select Client", "info");
            else
              dispatch(
                Show_MsgBox({
                  Button: SendOTP,
                  Question: "Do You Want to Delete Client?",
                  isdialog: true,
                  parem1: 1,
                  parem2: "Delete",
                  parem3: _client.strClientName,
                  parem4: dispatch,
                })
              );
          }}
          disabled={!_common.permission.IsDelete}
        >
          Delete&nbsp;<i className="bx bx-trash-alt"></i>
        </button>
        <button
          className="btn-fabgreen"
          onClick={(e) => {
            if (_client.strMasterDB.length === 0)
              AlertMessage("Please Select Client", "info");
            else
              dispatch(
                Show_MsgBox({
                  Button: Backup,
                  Question: `Do You Want to Backup Data?`,
                  isdialog: true,
                })
              );
          }}
          disabled={!_common.permission.IsBackup}
        >
          Backup Data&nbsp;<i className="bx bx-server"></i>
        </button>
        <button className="btn-fabgreen" onClick={(e) => Close_Click()}>
          Close&nbsp;<i className="fas fa-times"></i>
        </button>
      </div>
    </>
  );
}

export default ClientMaster;
